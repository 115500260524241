import React from "react";
import {useNotify, useQueryWithStore} from "react-admin";
import {Card, Spin} from "antd";

const LazyExpandedRow = (
    { lightRecord, resource, ExpandedRowComponent, refresh, extraProps={} }:
        { lightRecord: Record<string, any>, resource: string, ExpandedRowComponent: React.ReactNode, refresh?: Function, extraProps?: any }
) => {
    const notify = useNotify();
    const spinnerElement = <Spin size="large" tip="Loading"><Card style={{height: "100px"}}></Card></Spin>

    const { loaded, error, data } = useQueryWithStore ({
        type: 'getOne',
        resource: resource,
        payload: { id: lightRecord.id }
    });

    if(error)
        notify("Error fetching data")

    return (
        <>
            {
                data?
                    // @ts-ignore
                    <ExpandedRowComponent record={data} refresh={refresh} extraProps={extraProps}/>
                    :
                    spinnerElement
            }
        </>
    )
}


export default LazyExpandedRow;
