import { Card, Table, Tabs } from 'antd';
import React, { useState } from 'react';
import { useQueryWithStore } from 'react-admin';
import ReactJson from 'react-json-view';
import { formatDateAndTime } from '../../helpers/utils';
import SkeletonTable, { SkeletonTableColumnsType } from '../Common/SkeletonTable';
import {getTagForRuntimeStates} from "../Common/Tags";
const { TabPane } = Tabs;

const DependantServiceItems = () => {
    const [page, setPage] = useState<number>(1);
    const perPage = 20
    const { loading, error, data, total } = useQueryWithStore({
        type: 'getList',
        resource: 'dependant_service_items',
        payload: {
            pagination: { page: page, perPage: perPage },
            sort: { field: 'name', order: 'asc' },
            filter: {}
        }
    });

    const ExpandedRow = ({ record }: { record: Record<string, any> }) => {
        return (
            <Card>
                <Tabs defaultActiveKey="1" type='card'>
                    <TabPane tab="Declaration" key="1">
                        <ReactJson
                            style={{ padding: "20px" }}
                            src={record.declaration}
                            displayArrayKey={false}
                            displayDataTypes={false}
                            name="declaration"
                            displayObjectSize={false}
                        />
                    </TabPane>
                    {Object.keys(record.deployed_item).length !== 0 && <TabPane tab="Deployed Item" key="2">
                        <ReactJson
                            style={{ padding: "20px" }}
                            src={record.deployed_item}
                            displayArrayKey={false}
                            displayDataTypes={false}
                            name="deployed_item"
                            displayObjectSize={false}
                        />
                    </TabPane>}
                </Tabs>
            </Card>
        );
    };


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Service Item',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'State',
            dataIndex: 'runtime_state',
            key: 'runtime_state',
            render: (text: any, record: any) => getTagForRuntimeStates(record.runtime_state)
        },
        {
            title: 'Application',
            dataIndex: ['application', 'name'],
            key: 'application',
        },
        {
            title: 'Service',
            dataIndex: ['service', 'name'],
            key: 'service',
        },
        {
            title: 'Service Owner',
            dataIndex: ['service', 'owner', 'name'],
            key: 'owner',
        },
        {
            title: 'Consumer Team',
            dataIndex: ['consumer_team', 'name'],
            key: 'consumer_team',
        },
        {
            title: 'Last Modified',
            key: 'modified',
            render: (text: any, record: any) => formatDateAndTime(record.modified)
        },
    ];

    return (
        <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
            <Table
                rowKey='id'
                columns={columns}
                pagination={{ total: total, pageSize: perPage, current: page, onChange: page => setPage(page) }}
                dataSource={data}
                expandable={{ expandedRowRender: (record) => <ExpandedRow record={record} />, expandRowByClick: true }}
            />
        </SkeletonTable>
    )
}



export default DependantServiceItems;
