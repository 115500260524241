import { Modal, Tabs } from "antd";
import React from 'react';
import LdapSettingsForm from './LdapSettingsForm';
import FeedbackSettingsForm from "./FeedbackSettingsForm";
import SmtpSettingsForm from "./SmtpSettingsForm";
import GoogleOAuthSettingsForm from "./GoogleOAuthSettingsForm";
import CurrencySettingsForm from "./CurrencySettingsForm";
import ChargesSettingsForm from "./ChargesSettingsForm";
import SubmissionBuilderSettingsForm from "./SubmissionBuilderSettingsForm";

const AdminSettings = ({ isOpen, onCancel, onSave }: { isOpen: boolean, onCancel: (e: React.MouseEvent<HTMLElement>) => any, onSave: React.MouseEventHandler<HTMLElement> }) => {

    return (
        <Modal
            title="Admin Settings"
            visible={isOpen}
            onCancel={(e) => onCancel(e)}
            footer={null}
            width={1000}
        >
            <Tabs className="tabs-sliding" defaultActiveKey="1">
                <Tabs.TabPane tab="LDAP Settings" key="1">
                    <LdapSettingsForm />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Feedback Settings" key="2">
                    <FeedbackSettingsForm />
                </Tabs.TabPane>
                <Tabs.TabPane tab="SMTP Settings" key="3">
                    <SmtpSettingsForm />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Google OAuth Settings" key="4">
                    <GoogleOAuthSettingsForm />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Currency Settings" key="5">
                    <CurrencySettingsForm />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Charges Settings" key="6">
                    <ChargesSettingsForm />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Submission Builder Settings" key="7">
                    <SubmissionBuilderSettingsForm />
                </Tabs.TabPane>
            </Tabs>

        </Modal>
    )
}

export default AdminSettings;