import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, Switch } from 'antd';
import { useDataProvider, useNotify } from 'react-admin';
const { Title } = Typography;

const SmtpSettingsForm = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify()
  const [currentSettings, setCurrentSettings] = useState<any>({});
  const [loadedSettings, setLoadedSettings] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    dataProvider.getOne('general_settings', {id: "SMTP"})
      .then((data: any) => {
        setCurrentSettings(data?.data)
        setLoadedSettings(true)
      }).catch((error: any) => {
      if (error.status == 404) {
        setCurrentSettings({})
        setLoadedSettings(true)
      } else {
        console.log(error)
      }
    })
  }, [])

  if (!loadedSettings) {
    return null;
  }

  const onSubmit = (values: any) => {
    const submission = {
      "name": "SMTP",
      "config": values
    }
    if (currentSettings?.config) {
      // update existing
      dataProvider.update('general_settings', {
        id: currentSettings.id,
        data: submission,
        previousData: {id: currentSettings.id}
      })
        .then(response => {
          notify('Settings have been saved.')
        }).catch(error => {
        notify("Something went wrong", "warning")
      })
    } else {
      //create
      dataProvider.create('general_settings', { data: submission })
        .then(response => {
          setCurrentSettings(response?.data)
          notify('Settings have been saved.')
        }).catch(error => {
        console.error(error)
        notify("Something went wrong", "warning")
      })
    }
  }

  return (
    <>
      <Form form={form}
            labelAlign='left'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
              ENABLED: currentSettings?.['config']?.ENABLED
            }}
            onFinish={onSubmit}>

        <Form.Item valuePropName='checked' label="Enable SMTP" name="ENABLED" initialValue={currentSettings?.['config']?.ENABLED || false}>
          <Switch defaultChecked={currentSettings?.['config']?.ENABLED == true} />
        </Form.Item >

        <Form.Item
          label="Host"
          initialValue={currentSettings?.['config']?.EMAIL_HOST}
          name="EMAIL_HOST"
          rules={[{ required: true, message: 'Please input host' }]}
        >
          <Input placeholder="e.g. smtp.gmail.com" />
        </Form.Item>

        <Form.Item
          label="Port"
          initialValue={currentSettings?.['config']?.EMAIL_PORT}
          name="EMAIL_PORT"
          rules={[{ required: true, message: 'Please input port' }]}
        >
          <Input placeholder="port" />
        </Form.Item>

        <Form.Item
          label="Username"
          initialValue={currentSettings?.['config']?.EMAIL_HOST_USER}
          name="EMAIL_HOST_USER"
          rules={[{ required: true, message: 'Please input username' }]}
        >
          <Input placeholder="username" />
        </Form.Item>

        <Form.Item
          label="Password"
          initialValue={currentSettings?.['config']?.EMAIL_HOST_PASSWORD}
          name="EMAIL_HOST_PASSWORD"
          rules={[{ required: true, message: 'Please input password' }]}
        >
          <Input.Password placeholder="password" />
        </Form.Item>

        <Form.Item
          label="Use TLS"
          initialValue={currentSettings?.['config']?.EMAIL_USE_TLS || false}
          name="EMAIL_USE_TLS"
          valuePropName='checked'
          rules={[{ required: true}]}
        >
          <Switch defaultChecked={currentSettings?.['config']?.EMAIL_USE_TLS == true} />
        </Form.Item>

        <Form.Item >
          <Button type="primary" htmlType="submit" className="form-button">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </>
  )

}

export default SmtpSettingsForm;
