import { Input, Modal } from 'antd'
import React, { useState } from 'react'

const AddApplicationModal = ({ modalVisible, setModalVisible, handleModalSubmit }: { modalVisible: boolean, setModalVisible: Function, handleModalSubmit: Function }) => {
    const [appName, setAppName] = useState<String | null>(null)

    const handleOk = () => {
        setModalVisible(false)
        handleModalSubmit(appName)
    }

    const handleCancel = () => {
        setModalVisible(false)
    }

    return (
        <>
            <Modal
                title="Add New Application"
                visible={modalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >

                <Input onChange={(e)=>setAppName(e.target.value)} placeholder="Application Name" />

            </Modal>
        </>
    )
}

export default AddApplicationModal