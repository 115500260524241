import {Card, Form, Input, Space, Button} from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React, {useEffect, useState} from "react";
import {SelectItem} from "../../types";
import {usePermissions} from "react-admin";

const HTTPRequestForm = (props: any) => {
    const { permissions } = usePermissions();

  return (
    <>
      <Form.Item
        label="Target URL"
        name="target_url"
        rules={[{ required: true, message: 'Please enter Target URL' }]}
      >
        <Input placeholder="https://example.com/netorca" disabled={permissions !== "ADMIN"} />
      </Form.Item>
      <Form.Item
        label="Authorization"
        name="authorization"
        rules={[{ required: false, message: 'authorization header' }]}
      >
        <Input placeholder="Authorization" disabled={permissions !== "ADMIN"} />
      </Form.Item>
    </>
  )
}


export default HTTPRequestForm;