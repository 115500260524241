import React from "react";
import {Space} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import DeclarationDiff from "./DeclarationDiff";

// Used in change instance list and submission list pages

const ConsumerChangeInstanceExpandedRow = ({ record }: { record: Record<string, any> }) => {
    return (
        <>
            {
                record.log!=="" &&
                <Space style={{ margin: '10px' }}><InfoCircleOutlined /> Log: {record.log}</Space>
            }
            <DeclarationDiff change_instance={record} />
        </>
    )
}

export default ConsumerChangeInstanceExpandedRow;
