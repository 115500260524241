import ServiceItemsList from './ServiceItemsList';
import ServiceItemDetailCard from "./DetailView";
import {EditBase} from "react-admin";
import React from "react";

const ServiceItemDetail = (props: any) => {
    return (
        <EditBase {...props} >
            <ServiceItemDetailCard
                pov={"consumer"}
                serviceItemsResource={"service_items"}
                changeInstancesResource={"change_instances"}
                deployedItemsResource={"consumer_deployed_items"}
                healthcheckResource={"consumer_healthchecks"}
                healthcheckResponsesResource={"consumer_healthcheck_responses"}
            />
        </EditBase>
    )
}

export default {
    list: ServiceItemsList,
    edit: ServiceItemDetail
}
