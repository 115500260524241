import { Alert, Modal } from 'antd'
import React, { useState } from 'react'
import Editor from '@monaco-editor/react';
import yaml from 'js-yaml';


const AppMetadataModal = ({ modalVisible, setModalVisible, handleModalSubmit, appName, metadata }: { modalVisible: boolean, setModalVisible: Function, handleModalSubmit: (appName: string, metadata: object) => void, appName: string, metadata: any }) => {

    const [appMetadata, setAppMetadata] = useState<string>(metadata)
    const [isValidMetadata, setIsValidMetaData] = useState<boolean>(true)

    const handleOk = () => {
        try {
            const parsedData = yaml.load(appMetadata)
            if (typeof parsedData == 'object' && parsedData != null) {
                handleModalSubmit(appName, parsedData);
                setIsValidMetaData(true)
                setModalVisible(false)

            } else {
                console.error('invalid metadata')
            }
        } catch (e) {
            console.error(e)
            setIsValidMetaData(false)
        }


    }

    const handleCancel = () => {
        setModalVisible(false)
    }

    const handleEditorChange = (value: any, event: any) => {
        setAppMetadata(value)
    }

    return (
        <>
            <Modal
                title={`${appName} Metadata`}
                visible={modalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                {!isValidMetadata && <Alert message="YAML is invalid" type="error" />}
                <Editor
                    options={{
                        formatOnPaste: true,
                        minimap: {
                            enabled: false,
                        },
                    }}
                    height="50vh"
                    defaultLanguage="yaml"
                    value={(yaml.dump(metadata))}
                    onChange={handleEditorChange}
                />
            </Modal>
        </>
    )
}

export default AppMetadataModal;