import { CheckCircleFilled, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { faCalendar, faRocket, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { Card, Timeline, Typography } from 'antd';
import React from 'react';
import SkeletonData from '../Common/SkeletonData';
import TimelineItemRow from '../Common/TimelineItemRow';
import {useQuery} from "react-admin";
const { Title, Text } = Typography;

const getColorForState = (state: string) => {
    if (state == 'COMPLETED') {
        return 'green'
    }
    else if (state == 'ERROR') {
        return 'red'
    }
    return 'blue'
}

const getIconForState = (state: string) => {
    if (state == 'COMPLETED') {
        return <CheckCircleFilled title='Completed' style={{ color: 'green' }} />
    }
    else if (state == 'ERROR') {
        return <CloseCircleOutlined title='Error' style={{ color: 'red' }} />
    }
    return <ClockCircleOutlined title='Pending' style={{ color: 'blue' }} />
}


const ChangeInstanceLogWidget = ({ pov, filterValues }: { pov: 'CONSUMER' | 'SERVICE_OWNER', filterValues: any }) => {

    const { loading, data } = useQuery({
        type: 'getList',
        resource: pov==='CONSUMER'?"change_instances":"owner_change_instances",
        payload: {
            pagination: { page: 1, perPage: 5 },
            sort: { field: 'created', order: 'asc' },
            filter: {
                'service_id': filterValues?.service_id,
                'consumer_team_id': filterValues?.consumer_team_id,
                'application_id': filterValues?.application_id,
                light: true
            }
        }
    });

    return (
        <Card>
            <Title style={{marginBottom: '20px'}} level={5}>Change History</Title>
            <SkeletonData loading={loading}>
                <Timeline
                    pending={false}
                    className="timelinelist"
                >
                    {data && Object.keys (data).length === 0 && <p>No Change History available</p>}
                    {data && data.map ((ci: any, index: any) => (
                        <Timeline.Item dot={getIconForState (ci.state)} color={getColorForState (ci.state)} key={index}>
                            <Title style={{display: 'inline'}} level={5}>{ci.application.name} - {ci.service_item.name}</Title>
                            <TimelineItemRow icon={faRocket} text={ci.service.name}/>
                            {
                                pov == 'SERVICE_OWNER' &&
                                <TimelineItemRow icon={faUserFriends} text={ci.consumer_team.name}/>
                            }
                            <TimelineItemRow icon={faCalendar} text={new Date (ci.modified).toLocaleDateString ('en-GB') + " " + new Date (ci.modified).toLocaleTimeString ('en-GB', {timeZone: "Europe/London"})}/>
                        </Timeline.Item>
                    ))}
                </Timeline>
            </SkeletonData>
        </Card>
    )
}

export default ChangeInstanceLogWidget