import React from 'react';
import { ReduxState, useQueryWithStore } from 'react-admin';
import { useSelector } from 'react-redux';
import WelcomeScreen from '../Welcome';
import BaseDashboard from "./Dashboard";

const Dashboard = () => {
  const { loaded, error, data: teamData } = useQueryWithStore({
    type: 'getList',
    resource: 'teams',
    payload: {
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'name', order: 'asc' },
      filter: {}
    }
  });
  const welcomeScreenShown = useSelector((state: ReduxState) => state.ui.welcomeScreenShown);
  const viewMode = localStorage.getItem('pov_preference') || useSelector((state: ReduxState) => state.ui.viewMode);

  return (
    <>
      {
        viewMode === 'CONSUMER'?
            <BaseDashboard
                pov={viewMode}
                statisticsResource={"consumer_statistics"}
                applicationsResource={"applications"}
                changeInstancesResource={"change_instances"}
                servicesResource={"consumer_services"}
                chargesResource={"consumer_charges"}
            />
            :
            <BaseDashboard
                pov={viewMode}
                statisticsResource={"serviceowner_statistics"}
                teamsResource={"consumer_teams"}
                applicationsResource={"owner_applications"}
                changeInstancesResource={"owner_change_instances"}
                servicesResource={"services"}
                chargesResource={"serviceowner_charges"}
            />
      }
      {teamData && teamData.length == 0 &&  !welcomeScreenShown && <WelcomeScreen />}
    </>
  )
}

export default Dashboard;