import {Card, Col, Divider, Row, Select, Skeleton, Tabs, Typography} from 'antd';
import { TimeUnit } from 'chart.js';
import React, { useEffect, useState } from 'react';
import {useDataProvider, usePermissions, useQuery} from 'react-admin';
import {DashboardProps, ExtendedDataProvider} from '../../types';
import AverageTimeCompletionGraphWidget from './AverageTimeCompletionGraphWidget';
import ChangeInstanceLogWidget from './ChangeInstanceLogWidget';
import ChangeInstancesWidget from './ChangeInstancesWidget';
import NumberCompletedChangesGraphWidget from './NumberCompletedChangesGraphWidget';
import ServiceItemsWidget from './ServiceItemsWidget';
import useGetGraphStatistics from './useGetGraphStatistics';
import GeneralFilter from "../ChangeInstances/GeneralFilter";
import ChargeList from "../Marketplace/ChargeList";
import useGetGeneralSettings from "../../providers/useGetGeneralSettings";

const { Option } = Select;
const { Title } = Typography;
const { TabPane } = Tabs;

const BaseDashboard = ({ pov, applicationsResource, teamsResource, servicesResource, changeInstancesResource, statisticsResource, chargesResource }: DashboardProps) => {
    const dataProvider: ExtendedDataProvider = useDataProvider()
    const [dashboardWidgets, setDashboardWidgets] = useState<JSX.Element[]>([])
    const [filterValues, setFilterValues] = useState<any>({})
    const { permissions } = usePermissions();
    const generalSettings = useGetGeneralSettings();
    const chargesSettings = generalSettings.find(setting => setting.name=='ChargesSettings')?.config;

    const isChargesVisible = () => {
        if(chargesSettings?.ENABLED == true){
            if(chargesSettings.hide_for_serviceowners == true && pov === "SERVICE_OWNER")
                return false

            if(chargesSettings.hide_for_consumers == true && pov === "CONSUMER")
                return false

            if(chargesSettings.only_admins_can_see == true && permissions !== "ADMIN")
                return false
        }
        return true
    }
    const isChargesVisible_ = isChargesVisible()

    const { setGraphIntervalUnit, processedChangesGraphData, avgTimeCompletionGraphData, graphIntervalUnit } = useGetGraphStatistics(pov)


    useEffect(() => {
        const dashItems: JSX.Element[] = []
        dataProvider.getAction(statisticsResource, {
            verb: 'basics',
            filter: filterValues
        })
            .then((response: any) => {
                dashItems.push(<ChangeInstancesWidget data={response.data} />)
                dashItems.push(<ServiceItemsWidget data={response.data} />)
                setDashboardWidgets(dashItems)
            })
            .catch((error) => { console.error(error) })

    }, [filterValues, pov])

    useEffect(() => {
        setFilterValues({})
    }, [pov])

    return (
        <>
            <Title level={3}>Dashboard</Title>
            <Tabs style={{ paddingLeft: '24px' }} defaultActiveKey='1'>
                <TabPane tab="Statistics" key="1">
                    <div className="layout-content">
                        <Skeleton active loading={dashboardWidgets.length == 0} />
                        <Row className="rowgap-vbox" gutter={[24, 12]}>
                            <Col xs={24} lg={16}>
                                <Row gutter={10} className="rowgap-vbox" style={{ marginRight: '9%', marginBottom: '15px', justifyContent: 'flex-start' }}>
                                        <GeneralFilter
                                            filterValues={filterValues}
                                            setFilterValues={setFilterValues}
                                            applicationsResource={pov==="CONSUMER"?applicationsResource:undefined}
                                            teamsResource={pov==="CONSUMER"?undefined:teamsResource}
                                            servicesResource={servicesResource}
                                            spaceDirection={"horizontal"}
                                            style={{ width: 'auto' }}
                                            stateFilterEnabled={false}
                                            changeTypeFilterEnabled={false}
                                        />
                                </Row>
                                <Row gutter={[12, 16]} style={{ marginBottom: '5px' }} >
                                    {dashboardWidgets.map((widget, index) => <Col key={index} span={12}>{widget}</Col>)}
                                </Row>
                                <Divider />
                                <Row style={{ marginBottom: '10px' }} justify='end'>
                                    <Select defaultValue='month' style={{ display: 'block' }} onChange={(value: TimeUnit) => setGraphIntervalUnit(value)}>
                                        <Option key={0} value="month">Monthly</Option>
                                        <Option key={1} value="week">Weekly</Option>
                                        <Option key={2} value="day">Daily</Option>
                                    </Select>
                                </Row>
                                <Row gutter={[12, 12]}>
                                    <Col xs={24} lg={12}>
                                        <Card>
                                            <AverageTimeCompletionGraphWidget unit={graphIntervalUnit} data={avgTimeCompletionGraphData} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Card>
                                            <NumberCompletedChangesGraphWidget unit={graphIntervalUnit} data={processedChangesGraphData} />
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={24} lg={8}>
                                <ChangeInstanceLogWidget pov={pov} filterValues={filterValues}/>
                            </Col>
                        </Row>
                    </div>
                </TabPane>
                {
                    isChargesVisible_ &&
                    <TabPane tab="Charges" key="2">
                        <ChargeList resource={chargesResource} basePath={chargesResource}/>
                    </TabPane>
                }
            </Tabs>
        </>
    )
}

export default BaseDashboard;