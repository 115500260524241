import { Button, Card, Form, Input, Typography } from "antd";
import * as React from "react";
import {Record, useDataProvider, useNotify, usePermissions, useQuery, useRedirect} from 'react-admin';
import DebounceSelect from "../Common/DebounceSelect";

const { Title } = Typography

const DependantTeamsForm = ({ record }: { record: Record }) => {
    const { permissions } = usePermissions();
    const [form] = Form.useForm();
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const [selectValues, setSelectValues] = React.useState<any>([]);

    // const { data, loading, error } = useGetOne('services', record.id);
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'services',
        payload: { id: record.id },
    });
    const onSubmit = (values: any) => {
        //@ts-ignore
        let preparedData = []
        //@FIXME better handling - diff data shape when coming from endpoint vs direct form
        if (typeof values.dependant_teams[0] == 'object') {
            preparedData = values.dependant_teams.map((item: any) => item.value)
        } else {
            preparedData = values.dependant_teams
        }

        dataProvider.update('services', {
            id: values.id,
            data: { dependant_teams: preparedData },
            previousData: { id: values.id }
        })
            .then(data => { notify('Save successful') })
            .catch((error: any) => {
                notify('Error submitting request', 'error')
                if (error.status == 400) {
                    for (const [key, value] of Object.entries<any>(error.body))
                        form.setFields([{ name: key, errors: value }])
                }
            })
    }

    React.useEffect(() => {
        if (data && data.dependant_teams) {
            const teams = data.dependant_teams.map((item: any) => item.id)
            setSelectValues(teams)
            form.setFieldsValue({
                dependant_teams: teams
            })
        }
    }, [data, loading])

    const fetchTeamList = async (values: string): Promise<any> => {
        //@ts-ignore
        return dataProvider.getList('all_teams', {
            pagination: { page: 1, perPage: 20 },
            sort: { field: "name", order: "asc" },
            filter: {
                'name': values,
                'exclude_own_team': true
            }
        }
        ).then((data: any) => {
            return data.data.map((result: any) => ({
                label: result.name,
                value: result.id
            }))
        })

    }

    return (
        <Form
            form={form}
            name="dependant_teams"
            className="row-col"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
        >
            <Form.Item
                name="dependant_teams"
                valuePropName="option"
                rules={[{ required: false, message: 'Please select Team Names' }]}
            >
                <DebounceSelect
                    mode="multiple"
                    showSearch
                    placeholder="Select Teams"
                    fetchOptions={fetchTeamList}
                    value={selectValues}
                    onChangeSelect={newValue => {
                        setSelectValues(newValue);
                        form.setFieldsValue({dependant_teams: newValue})
                    }}
                    style={{ width: '100%' }}
                    disabled={permissions !== "ADMIN"}
                />
            </Form.Item>
            <Form.Item
                hidden
                id="id"
                name="id"
                initialValue={record.id}
            >
                <Input />
            </Form.Item>
            {
                permissions === "ADMIN" &&
                <Form.Item>
                    <Button style={{ width: "100%" }} type="primary" htmlType="submit" className="form-button">Save</Button>
                </Form.Item>
            }
        </Form>
    );
}

const ManageDependantTeams = ({ record }: { record: any }) => (
    <Card style={{ padding: '20px' }}> 
        <Title level={5} style={{ textAlign: 'center', paddingBottom: '10px' }} className="mb-15">Manage Dependant Teams</Title>
        <DependantTeamsForm record={record} />
    </Card>
)

export default ManageDependantTeams;