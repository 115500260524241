import { RouteWithoutLayout } from "ra-core";
import * as React from "react";
import { Route } from 'react-router-dom';
import Layout from "./components/Layout";
import RegistrationPage from "./components/Registration/RegistrationPage";
import JoinTeam from "./components/Teams/JoinTeam";
import ProfileEdit from "./components/Users/Profile";
import BuilderWizard from "./components/SubmissionBuilder/BuilderWizard";

export default [
    <RouteWithoutLayout path="/signup"><RegistrationPage/></RouteWithoutLayout>,
    <RouteWithoutLayout path="/teams/join"><Layout><JoinTeam/></Layout></RouteWithoutLayout>,
    <Route key="profile" path="/profile"><ProfileEdit /></Route>,
    <Route key="submission_builder" path="/submission_builder"><BuilderWizard /></Route>

];