import * as React from "react";
import { SelectProps } from 'antd/es/select';
import debounce from 'lodash/debounce';
import { Select, Spin, Typography } from "antd";

const Option = Select.Option;

export interface DebounceSelectProps<ValueType = any>
    extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
    fetchOptions: (search: string) => Promise<ValueType[]>;
    debounceTimeout?: number;
    createable?: boolean;
    style?: React.CSSProperties;
    onChangeSelect?: (value: string, inputValue: string) => void;
    NotFoundSelectOption?: Function
}


function DebounceSelect<ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number
} = any, >({
      fetchOptions,
      onChangeSelect,
      debounceTimeout = 1000,
      createable = false,
      value,
      placeholder,
      id,
      showSearch,
      allowClear, 
      onClear,
      mode,
      disabled, style,
      NotFoundSelectOption
}: DebounceSelectProps) {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState<ValueType[]>([]);
    const [inputValue, setInputValue] = React.useState("");

    const fetchRef = React.useRef(0);
    React.useEffect(() => {
        fetchOptions("").then(options => {
            setOptions(options)
        })
    }, [])

    const onChange = (value: any) => {
        onChangeSelect?.(value, inputValue)
    }

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setInputValue(value)
            setFetching(true);
            fetchOptions(value).then(newOptions => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
        <Select<ValueType>
            mode={mode}
            labelInValue
            value={value}
            filterOption={false}
            onChange={onChange}
            id={id}
            showSearch={showSearch}
            allowClear={allowClear}
            onClear={onClear}
            placeholder={placeholder}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : "No Results"}
            disabled={disabled}
            {...(style && {style})}
        >
            {
                createable &&
                NotFoundSelectOption &&
                inputValue &&
                options.filter(o => o.label === inputValue).length === 0 &&
                NotFoundSelectOption(inputValue)
            }
            {
                options.map((option) => <Option key={option.value} value={option.value}>{option.label}</Option>)
            }
        </Select>
    );
}

export default DebounceSelect;