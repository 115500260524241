import React, {useState} from "react";
import {useQueryWithStore} from "react-admin";
import {getTagForChangeTypes, getTagForStates} from "../../Common/Tags";
import {formatDateAndTime} from "../../../helpers/utils";
import SkeletonTable, {SkeletonTableColumnsType} from "../../Common/SkeletonTable";
import {Button, Table} from "antd";
import {Link} from "react-router-dom";
import LazyExpandedRow from "../../Common/LazyExpandedRow";
import ChangeInstanceDetailCompact from "./ChangeInstanceDetailCompact";
import {onExpandOnlyOneRow} from "../../Common/tableUtils";


const ChangeInstancesTab = (props: any) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

    const { baseFilter, resource, submissionPageRedirectVisible } = props;
    const { data, error, loading } = useQueryWithStore({
        type: "getList",
        resource: resource,
        payload: {
            pagination: { page: 1, perPage: 20 },
            sort: { field: "id", order: "dsc" },
            filter: { ...baseFilter, light: true }
        },
    });

    let columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        }, {
            title: 'Change Type',
            dataIndex: 'change_type',
            key: 'change_type',
            render: (text: any, record: any) => getTagForChangeTypes(record.change_type),
        }, {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            render: (text: any, record: any) => getTagForStates(record.state),
        }, {
            title: 'Created',
            key: 'created',
            render: (text: any, record: any) => formatDateAndTime(record.created),
        },
    ];
    if(submissionPageRedirectVisible==true)
        columns.push(
            {
                title: '',
                dataIndex: 'submission',
                key: 'submission',
                render: (text: any, record: any) => (
                    <Button type="primary">
                        <Link to={`/submissions/${record.submission?.id}/`} >
                            Submission page
                        </Link>
                    </Button>
                )
            }
        )

    if (resource.includes("owner"))
        columns = columns.filter((col: any) => col.key !== "submission")

    return (
      <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
          <Table
            rowKey='id'
            columns={columns}
            expandable={{
                expandedRowRender: (record) => (
                    <LazyExpandedRow
                        lightRecord={record}
                        resource={resource}
                        ExpandedRowComponent={ChangeInstanceDetailCompact}
                        extraProps={{resource: resource}}
                    />
                ),
                expandRowByClick: true,
                onExpand: onExpandOnlyOneRow(setExpandedRowKeys),
                expandedRowKeys: expandedRowKeys,
            }}
            dataSource={data}
          />
      </SkeletonTable>
    )

}

export default ChangeInstancesTab;
