import {Button, Card, Form, Input, Select, Typography, Divider, Collapse, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {
  Edit,
  EditBase,
  useRecordContext,
  useDataProvider,
  useEditContext,
  useNotify,
  useQueryWithStore,
  useRedirect,
  Record, usePermissions
} from 'react-admin';
import {ExtendedDataProvider, SelectItem} from "../../types";
import HTTPRequestForm from "../Common/HTTPRequestForm";
import CrontabForm from "../Common/CrontabForm";
import ReactJson from "react-json-view";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const { Panel } = Collapse;
const { Title, Paragraph, Text, Link } = Typography;
const { Option } = Select;
const { confirm } = Modal;


const DeleteButton = ({ record, style = {} }: { record: Record, style: any },) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const handleClick = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete healthcheck?',
      onOk() {
        return dataProvider.delete('serviceowner_healthchecks', { id: record.id })
          .then(response => {
            notify('Healthcheck deleted')
            redirect('/serviceowner_healthchecks')
          }).catch(error => {
            notify("Something went wrong", "warning")
          })
      },
    });
  };
  return <Button style={style} danger onClick={handleClick}>Delete</Button>;
};


const CreateHealthcheckForm = (props: any) => {
  const [form] = Form.useForm();
  const notify = useNotify();
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const dataProvider: ExtendedDataProvider = useDataProvider();
  const { record, loading, loaded} = useEditContext();
  const [services, setServices] = useState<SelectItem[] | null>(null);
  const { loaded: servicesLoaded, error: servicesError, data: serviceData } = useQueryWithStore({
    type: 'getList',
    resource: 'services',
    payload: {
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'name', order: 'asc' },
      filter: {}
    }
  });

  useEffect(() => {
    const services: SelectItem[] = []
    if (serviceData) {
      for (const item of serviceData) {
        services.push({ text: item.name, value: item.id });
      }
      setServices(services);
    }
  }, [serviceData]);

  useEffect(() => {
    // if the record exists, fill header field with existing data
    if (record) {
      const headers_: {}[] = []
      if (record.headers) {
        Object.entries(record.headers).map(([key, value]) => headers_.push(
          { header_key: key, header_value: value })
        )
      }
      form.setFieldsValue({headers: headers_});
    }
  }, []);

  const onSubmit = (values: any) => {
    if (values.headers){
      values['headers'] = Object.fromEntries(values.headers.map(
        (x: { header_key: string; header_value: string; }
        ) => [x.header_key, x.header_value]))
    }
    if (record) {
      if (typeof values.service == "object") {
        values.service = values.service.value
      }
      dataProvider.update('serviceowner_healthchecks', { id: record.id, data: values, previousData: { id: record.id } })
        .then(() => {
          notify('Healthcheck updated successfully');
          redirect('/serviceowner_healthchecks')
        })
        .catch((error: any) => {
          if (error.status == 400) {
            for (const [key, value] of Object.entries<any>(error.body)) {
              form.setFields([
                { name: key, errors: value }
              ])
            }
          }
        })
    } else {
      dataProvider.create('serviceowner_healthchecks', {data: values})
        .then(() => {
          notify('Healthcheck created successfully');
          redirect('/serviceowner_healthchecks')
        })
        .catch((error: any) => {
          if (error.status == 400) {
            for (const [key, value] of Object.entries<any>(error.body)) {
              form.setFields([
                {name: key, errors: value}
              ])
            }
          }
        })
    }
  }

  return (
    <Form
      form={form}
      name="create_healthcheck"
      labelCol={{ span: 4 }}
      className="row-col"
      labelAlign="left"
      initialValues={record?
        {...record, service: { value: record.service.id, label: record.service.name }}:
        { remember: true, trust_env: true, verify: true }}
      onFinish={onSubmit}
    >
      <Form.Item
        label="Service"
        name="service"
        rules={[{ required: true, message: 'Please select a service' }]}
      >
        <Select
          placeholder="Select Service"
          style={{ width: '100%' }}
          disabled={permissions !== "ADMIN"}
        >
          {services && services.map(service => {
            return (
              <Option key={service.value} value={service.value}>
                {service.text}
              </Option>
            )
          })}
        </Select>
      </Form.Item>

      <HTTPRequestForm form={form} />
      <CrontabForm form={form} />

      <Form.Item>
        {
            permissions === "ADMIN" &&
            <Button style={{ width: "20%" }} type="primary" htmlType="submit" className="form-button">{record? "Update":"Create"}</Button>
        }
        {
            record &&
            permissions === "ADMIN" &&
            <DeleteButton style={{ width: "20%", float: "right" }} record={record} />
        }
      </Form.Item>
    </Form>

  )
}
const HealthcheckCreate = (props: any) => {
  return (
    <>
      <Card
        className=" header-solid h-full ant-card pt-0"
        bordered={false}
      >
        <Title level={4}>Healthcheck</Title>
        <Collapse defaultActiveKey={[]} >
          <Panel header="How it works?" key="1" style={{ marginBottom: '20px' }}>
            <Typography>
              <Paragraph>
                Healthchecks are used to check whether a service item is operating normally by sending
                a request to an endpoint defined by the Service Owner. Service Owners are responsible
                for implementing this endpoint on their own side and responding with a 200 status code
                if the service item is healthy, or a 400 status code if it is unhealthy. Healthchecks
                can be scheduled using crontab or triggered manually.
              </Paragraph>

              <Paragraph>
                If the healthcheck is triggered for a service, an example of the body of the request will be:
                <ReactJson
                  style={{ padding: '20px' }}
                  src={{
                    "service": "service.name",
                    "service_items": [
                      {
                        "id": "service_item_1.id",
                        "name": "service_item_1.name",
                        "application": "service_item_1.application.name",
                        "consumer": "service_item_1.application.owner.name",
                      },
                      {
                        "id": "service_item_2.id",
                        "name": "service_item_2.name",
                        "application": "service_item_2.application.name",
                        "consumer": "service_item_2.application.owner.name",
                      },
                    ],
                  }}
                  collapsed={false}
                  enableClipboard={false}
                  displayArrayKey={false}
                  displayDataTypes={false}
                  name="body"
                  displayObjectSize={false}
                />
                While if it is triggered for a particular service item, the body will be:
                <ReactJson
                  style={{ padding: '20px' }}
                  src={{
                    "service": "service.name",
                    "service_item": "service_item.name",
                    "application": "service_item.application.name",
                    "consumer": "service_item.application.owner.name",
                  }}
                  collapsed={false}
                  enableClipboard={false}
                  displayArrayKey={false}
                  displayDataTypes={false}
                  name="body"
                  displayObjectSize={false}
                />
              </Paragraph>
              <Title level={4}>Responses</Title>
              <Paragraph>
                Responses are generated for each service item, whether the health check is triggered for
                a service or a particular service item. <br/> If the Service Owner's health check endpoint is
                triggered for a service, the expected response must have the same schema as shown below:
                <ReactJson
                  style={{ padding: '20px' }}
                  src={{
                    "service_item_1_id": {"status_code": 200, "body": {"key": "value"}},
                    "service_item_2_id": {"status_code": 400, "body": {"key": "value"}},
                  }}
                  collapsed={false}
                  enableClipboard={false}
                  displayArrayKey={false}
                  displayDataTypes={false}
                  name="body"
                  displayObjectSize={false}
                />
                The "service_item_x_id" refers to the service item's id defined in NetOrca. And, the value of
                "status_code" defines the healthiness of the service item. If status_code is 200, it means the
                service item is healthy. And, status_code 400 means unhealthy. <br/><br/>
                In service item healthcheck trigger, the status code of the response directly defines the healthiness
                of the service item.
              </Paragraph>
              <Divider />
              <Paragraph>
                <ul>
                  <li>
                    The healthcheck is sent to the target url defined below
                  </li>
                  <li>
                    Authentication can, also, be provided by "AUTHORIZATION" header field.
                    Some examples for this field are as follows:<br />
                    <Text code>Api-Key "api key"</Text>
                    <Text code>Token "token"</Text>
                    <Text code>Bearer/JWT "token"</Text>
                  </li>
                  <li>
                    The responses are stored in "responses" tab in Healthchecks' list page. Each response
                    contains the status code and the body return from target endpoint
                  </li>
                  <li>
                    By default, only the last 5 responses are stored in our database
                  </li>
                  <li>
                    Manual triggers are limited to 3 times per minute.
                  </li>
                </ul>




              </Paragraph>
            </Typography>
          </Panel>
        </Collapse>
        {props.id &&
            <Edit {...props}>
                <CreateHealthcheckForm />
            </Edit>
        }
        {!props.id && <CreateHealthcheckForm />}
      </Card>
    </>
  )
}

export default HealthcheckCreate;
