import { BookOutlined } from '@ant-design/icons';
import { Button, Card, Col, Modal, Row, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';
import Column from 'antd/lib/table/Column';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { setViewMode, setWelcomeScreenShown } from '../../actions/uiActions';
import { faBook, faListUl, faBinoculars } from '@fortawesome/free-solid-svg-icons';
import FaIcon from "../Icons/FaIcon";
import { useHistory } from "react-router-dom";

const { Title, Link } = Typography;

interface WelcomeScreenProps {
    onClose?: Function
}

const WelcomeScreen = (props: WelcomeScreenProps) => {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const handleClose = () => {
        setIsModalVisible(false)
        dispatch(setWelcomeScreenShown(true));
        props.onClose && props.onClose()
    }

    const changeView = (view: 'SERVICE_OWNER' | 'CONSUMER') => {
        dispatch(setViewMode(view));
        setIsModalVisible(false)
        dispatch(setWelcomeScreenShown(true));
        props.onClose && props.onClose()
        history.push('/')
    }

    return (
        <Modal
            visible={isModalVisible}
            footer={null}
            className='layout-dashboard'
            width={1000}
            title="Getting Started"
            onCancel={handleClose}
        >
            <Title level={3}>Welcome to NetOrca</Title>
            <Row gutter={16}>
                <Col span={8}>
                    <Card className='help-card criclebox' title='Documentation' bordered={false}>
                        <Row  className='help-card-row'  justify='center' align='middle'>
                            <Col span={6}>
                                <span style={{ 'display': 'inline-block' }} className="icon-box">
                                    {FaIcon(faBook)}
                                </span>

                            </Col>
                            <Col span={18}>
                                Please see the NetOrca documentation here<br />
                                <Link target={'_blank'} href='https://docs.netorca.io'>https://docs.netorca.io</Link>

                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card className='help-card criclebox' title="Service Catalogue" bordered={false}>
                        <Row  className='help-card-row' justify='center' align='middle'>
                            <Col span={6}>
                                <span style={{ 'display': 'inline-block' }} className="icon-box">
                                    {FaIcon(faListUl)}
                                </span>

                            </Col>
                            <Col span={18}>
                                <RouterLink onClick={handleClose} to={`browse_services`} component={Link}>View Services</RouterLink>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card className='help-card criclebox' title="View Modes" bordered={false}>
                        <Row className='help-card-row' justify='center' align='middle'>
                            <Col span={6}>
                                <span style={{ 'display': 'inline-block' }} className="icon-box">
                                    {FaIcon(faBinoculars)}
                                </span>
                            </Col>
                            <Col span={18}>
                                <Row><Link onClick={() => changeView('SERVICE_OWNER')}> Service Owner</Link></Row>
                                <Row><Link onClick={() => changeView('CONSUMER')}> Consumer</Link></Row>

                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Modal>
    )
}

export default WelcomeScreen;