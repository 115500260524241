// @ts-nocheck

import React, { useState } from 'react';
import {Card, Select} from 'antd';
import {ExtendedDataProvider} from "../../types";
import {Link, useDataProvider} from "react-admin";
import { Comment } from 'antd';
import FaIcon from "../Icons/FaIcon";
import {faRocket, faThLarge} from "@fortawesome/free-solid-svg-icons";


let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;
const { Option } = Select;


const SearchBar = (props:any) => {
    const dataProvider: ExtendedDataProvider = useDataProvider();
    const [data, setData] = useState<any>([]);

    const fetch = (value: string, callback: Function) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        currentValue = value;

        const fetchData = () => {
            dataProvider.getAction('search', {verb: value, filter: {}})
                .then((response: any) => {
                    if (currentValue === value){
                        callback(response.data)}
                })
                .catch((error: any) => { console.error(error) })
        };
        if (value) {
            timeout = setTimeout(fetchData, 300);
        } else {
            callback([]);
        }
    };

    const handleSearch = (newValue: string) => {
        fetch(newValue, setData);
    };

    const getPOVText = (pov:string) => {
        if (pov==="consumer")
            return "Consumer"
        else if (pov==="serviceowner")
            return "Service Owner"
        return null
    };

    const CardForService = (record: any) => {
        const service = record.record
        return (
            <Link to={`/${service.pov==="consumer"?"browse_services":"services"}/#${service.name}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                <Comment
                    author={service.model}
                    avatar={FaIcon(faRocket)}
                    content={<p style={{"color": "black"}}>{service.name}</p>}
                    datetime={<span>{getPOVText(service.pov)}</span>}
                    className='text-sm'
                />
            </Link>
        )
    };

    const CardForServiceItem = (record: any) => {
        const service_item = record.record
        return (
            <Link to={`/${service_item.pov==="consumer"?"service_items":"owner_service_items"}/${service_item.id}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                <Comment
                    author={service_item.model}
                    avatar={FaIcon(faRocket)}
                    content={<p style={{"color": "black"}}>{service_item.name}</p>}
                    datetime={<span>{getPOVText(service_item.pov)}</span>}
                    className='text-sm'
                />
            </Link>
        )
    };

    const CardForApplication = (record: any) => {
        const application = record.record
        return (
            <Link to={`/${application.pov==="consumer"?"applications":"owner_applications"}/#${application.name}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                <Comment
                    author={application.model}
                    avatar={FaIcon(faThLarge)}
                    content={<p style={{"color": "black"}}>{application.name}</p>}
                    datetime={<span>{getPOVText(application.pov)}</span>}
                    className='text-sm'
                />
            </Link>
        )
    };


    return (
        <Select
            showSearch
            value={null}
            placeholder={"Search"}
            style={props.style}
            defaultActiveFirstOption={false}
            notFoundContent={false}
            suffixIcon={null}
            filterOption={false}
            onSearch={handleSearch}
        >
            {
                Object.entries(data).map(([key, value]) => {
                    // @ts-ignore
                    return (
                        <Option value={key}>
                            {value.model==="Application"?<CardForApplication record={value}/>:<></>}
                            {value.model==="ServiceItem"?<CardForServiceItem record={value} />:<></>}
                            {value.model==="Service"?<CardForService record={value} />:<></>}
                        </Option>
                    )
                })
            }
        </Select>
    );
};

export default SearchBar;
