import {useDataProvider, useNotify, useQueryWithStore} from "react-admin";
import React, {useEffect, useState} from "react";
import {Button, Dropdown, Menu, Space, Typography} from "antd";
import {ExtendedDataProvider} from "../../../types";
import {DownOutlined} from "@ant-design/icons";
import TeamCreateModal from "./TeamCreateModal";
import {setActiveTeamLocalStorage} from "../../Common/utils";

const { Title } = Typography;

const ActivateMembership = () => {
    const notify = useNotify();
    const [activeTeam, setActiveTeam] = useState<string>("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [menu, setMenu] = useState<any>(<Menu items={[]}/>);
    const dataProvider: ExtendedDataProvider = useDataProvider();

    const { loaded, error, data: membershipsData } = useQueryWithStore({
        type: 'getList',
        resource: 'user_memberships',
        payload: {
            pagination: { page: 1, perPage: 20 },
            sort: { field: 'id', order: 'asc' },
            filter: {}
        }
    });

    const handleActiveTeamChange = (e:any) => {
        if(e.key==='null')
            return

        if(e)
            dataProvider.getAction('user_memberships', { id: e.key, verb: 'activate' })
                .then((response: any) => {
                    notify('Active team changed')
                    localStorage.setItem('role', response.data.role)
                    setActiveTeamLocalStorage (response.data.team)
                    localStorage.setItem('pov_preference', response.data.pov_preference==="serviceowner"?"SERVICE_OWNER":"CONSUMER");
                    window.location.reload()
                })
                .catch((error: any) => notify(JSON.stringify(error.body), 'error'))
    }

    useEffect(() => {
        if (membershipsData?.filter((mData: any) => mData.active == true).length > 0) {
            setActiveTeam(membershipsData.filter((mData: any) => mData.active == true)[0].team_name)
            setMenu(
                <Menu onClick={handleActiveTeamChange}>
                    {
                        membershipsData.map((mData: any) => (<Menu.Item key={mData.id}>{mData.team_name}</Menu.Item>))
                    }
                    <Menu.Divider />
                    <Menu.Item key={null}>
                        <Button onClick={()=>setIsModalOpen(true)} size={"small"} style={{ width: '100%'}} type="primary">
                            Create a new team
                        </Button>
                    </Menu.Item>
                </Menu>
            );
        }
    }, [membershipsData]);

    return (
        <>
            <Space align={"baseline"} >
                Active Team:
                <Dropdown overlay={menu} trigger={['click']}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Button size={"small"} style={{height: '30px', lineHeight: '30px'}}>
                            <Space align={"baseline"} >
                                <Title level={5} >{activeTeam}</Title><DownOutlined />
                            </Space>
                        </Button>
                    </a>
                </Dropdown>
                <TeamCreateModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
            </Space>
        </>
    );
}


export default ActivateMembership;
