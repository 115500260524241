import React, {useEffect, useState} from "react";
import {Checkbox, Input, Row, Select, Space, Typography} from "antd";
import {CheckboxValueType} from "antd/lib/checkbox/Group";
import {getTagForChangeStatus, getTagForRuntimeStates} from "../../Common/Tags";
import {ExtendedDataProvider, SelectItem} from "../../../types";
import {useDataProvider} from "react-admin";
const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;


const ServiceItemFilterPanel = ({filterValues, setFilters}: {filterValues: any, setFilters: Function}) => {
    const dataProvider: ExtendedDataProvider = useDataProvider();

    const [applications, setApplications] = useState<SelectItem[] | null>(null);
    const [services, setServices] = useState<SelectItem[] | null>(null);

    // initial filter param from url
    const [initialApplication, setInitialApplication] = useState<string|null>(filterValues.application_id)

    const onAppSelect = (value: string) => setFilters({ ...filterValues, application_id: [value] }, {});
    const onServiceSelect = (value: string) => setFilters({ ...filterValues, service_id: [value] }, {});
    const onSearch = (value: string) => setFilters({ ...filterValues, name: value }, {});

    const applicationsResource = "applications";
    useEffect(() => {
        if(applicationsResource){
            dataProvider.getFilterOptions(
                applicationsResource,
                {
                    pagination: { page: 1, perPage: 20 },
                    sort: { field: 'name', order: 'asc' },
                    filter: {}
                })
                .then(({ data }) => {
                    const appFilter: any[] = []
                    if (data) {
                        for (let item of data) {
                            appFilter.push({ 'text': item.name, 'value': item.id })
                            if(item.id.toString()===initialApplication)
                                setInitialApplication(item.name)
                        }
                        setApplications(appFilter);
                    }
                })
                .catch(error => {console.log(error)})
        }
    }, [applicationsResource])

    const servicesResource = "consumer_services";
    useEffect(() => {
        if(servicesResource){
            dataProvider.getFilterOptions(
                servicesResource,
                {
                    pagination: { page: 1, perPage: 20 },
                    sort: { field: 'name', order: 'asc' },
                    filter: {}
                })
                .then(({ data }) => {
                    const serviceFilter: any[] = []
                    if (data) {
                        for (let item of data) {
                            serviceFilter.push({ 'text': item.name, 'value': item.id })
                        }
                        setServices(serviceFilter);
                    }
                })
                .catch(error => {console.log(error)})
        }
    }, [servicesResource])

    return (
        <Space direction={"vertical"} align={"start"}>
            <h6 className='text-sm text-muted'>Name</h6>
            <Search
                className='item-search'
                allowClear
                placeholder="Search"
                onSearch={onSearch}
                enterButton
                style={{ width: '100%', marginBottom: '10px' }}
            />
            <h6 className='text-sm text-muted'>Application</h6>
            {
                applications &&
                <Select
                    data-cy="application-selector"
                    defaultValue={initialApplication}
                    placeholder="Select Application"
                    style={{ width: '100%' }}
                    onChange={onAppSelect}
                >
                    <Option key={0} value="">All Apps</Option>
                    {
                        applications &&
                        applications.map(app => <Option key={app.value} value={app.value}>{app.text}</Option>)
                    }
                </Select>
            }


            <h6 className='text-sm text-muted'>Service</h6>
            <Select
                data-cy="service-selector"
                value={services && filterValues.service_id}
                placeholder="Select Service"
                style={{ width: '100%' }}
                onChange={onServiceSelect}
            >
                <Option key={0} value="">All Services</Option>
                {services?.map(service => <Option key={service.value} value={service.value}>{service.text}</Option>)}
            </Select>


            <h6 className='text-sm text-muted'>Change State</h6>
            <Checkbox.Group
                value={filterValues?.change_state?.split(',')}
                onChange={(checked: Array<CheckboxValueType>) => setFilters({ ...filterValues, 'change_state': checked.join() }, {})}
            >
                {['ALL_CHANGES_COMPLETED', 'CHANGES_APPROVED', 'CHANGES_PENDING', 'CHANGES_REJECTED', 'CHANGES_ERRORED'].map(tag => (
                    <Row style={{ 'paddingBottom': '10px' }}>
                        <Checkbox style={{ float: 'right' }} value={tag}>{getTagForChangeStatus(tag)}</Checkbox>
                    </Row>
                ))}
            </Checkbox.Group>


            <h6 className='text-sm text-muted'>Runtime State</h6>
            <Checkbox.Group
                data-cy="runtime-state-checkbox-group"
                value={filterValues?.runtime_state?.split(',')}
                onChange={(checked: Array<CheckboxValueType>) => setFilters({ ...filterValues, 'runtime_state': checked.join() }, {})}
            >
                {['IN_SERVICE', 'REQUESTED', 'DECOMMISSIONED'].map(tag => (
                    <Row key={tag} style={{ 'paddingBottom': '10px' }}>
                        <Checkbox style={{ float: 'right' }} value={tag}>{getTagForRuntimeStates(tag)}</Checkbox>
                    </Row>
                ))}
            </Checkbox.Group>
        </Space>
    )
}

export default ServiceItemFilterPanel;
