import {FilterValue} from "antd/lib/table/interface";
import React from "react";

export const tableOnChangeGenerator = (setSort: Function, page: number, setPage: Function) => {
    const handleTableChange = (pagination: any, filters: Record<string, FilterValue | null>, sorter: any, action:any) => {
        if (action.action === "sort") {
            const sorterKey = sorter.column?.sorter?.key || sorter.columnKey
            setSort(sorterKey)

        } else if (action.action === "paginate") {
            setPage(pagination.current)
        }
    }
    return handleTableChange
}


export const onExpandIcon = (toggleRowExpansion: Function, onExpand: Function|undefined) => {
    const onExpandByIcon = (record: any, expanded: boolean, e:any) => {
        toggleRowExpansion(record.name);
        if (expanded && onExpand)
            onExpand(expanded, record);
    }

    return (props: any) => {
        const { expanded, onExpand, record } = props
        return (
            expanded ? (
                <button
                    type="button"
                    className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
                    aria-label="Collapse row"
                    onClick={(e) => onExpandByIcon(record, !expanded, e)}
                ></button>
            ) : (
                <button
                    type="button"
                    className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
                    aria-label="Expand row"
                    onClick={(e) => onExpandByIcon(record, !expanded, e)}
                ></button>
            ))
    }
}

export const onExpandOnlyOneRow = (setExpandedRowKeys: Function) => {
    // This method is used to generate the following method: Table.expandable.onExpand
    // To avoid expanding multiple rows to be open in one table at the same time.
    // This is crucial in tables with LazyExpandedRow component
    const onExpand = (expanded: boolean, record: any) => {
        if (expanded)
            setExpandedRowKeys([record.id])
        else
            setExpandedRowKeys([])
    }
    return onExpand
}
