import React, {useEffect, useState} from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title as ChartTitle,
    Tooltip,
    Legend,
    TimeUnit
} from 'chart.js';
import {ExtendedDataProvider} from "../../types";
import {useDataProvider} from "react-admin";
import {getCurrency} from "../Common/utils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ChartTitle,
    Tooltip,
    Legend,
    TimeScale
);

const ChargeChart = ({ resource, filterValues }: { resource: string, filterValues:any}) => {
    const dataProvider: ExtendedDataProvider = useDataProvider()
    const [labels, setLabels] = useState<any>([])
    const [chartData, setChartData] = useState<any>([])
    const currency = getCurrency()
    const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: currency});

    const dateOptions = { year: 'numeric', month: '2-digit' };

    useEffect(() => {
        dataProvider.getAction(resource,
            { verb: 'graphs', filter: filterValues })
            .then((response: any) => {
                const labels = Object.entries(response.data).map(
                    ([key, value]) => {
                        // @ts-ignore
                        const newDate = new Date(value.start_min);
                        // @ts-ignore
                        return newDate.toLocaleDateString(undefined, dateOptions)
                    }
                )
                setLabels(labels)

                // @ts-ignore
                const chartData = Object.entries(response.data).map(([key, value]) => value.total_charge)
                setChartData(chartData)

            })
    }, [filterValues])

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: false,
            },
        },
        scales: {
            y: {
                afterTickToLabelConversion: function (chart: any) {
                    const ticks = chart.ticks;
                    chart.ticks = [];
                    for(const t in ticks)
                        chart.ticks.push({value: ticks[t]["value"], label: formatter.format(ticks[t]["value"])});
                }
            }
        },
    };
    const data = {
        labels,
        datasets: [
            {
                label: 'Total Charge',
                data: chartData,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };
    return (
        <>
            {
                chartData &&
                <Line options={options} data={data} />
            }
        </>
    )
}
export default ChargeChart;
