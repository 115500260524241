import { message } from 'antd';
import { NoticeType } from 'antd/lib/message';
import PropTypes from 'prop-types';
import {
    getNotification, hideNotification, useTranslate,
} from 'ra-core';
import { NotificationProps } from 'ra-ui-materialui';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Notification = (props: NotificationProps ) => {
    const {
        classes: classesOverride,
        type,
        className,
        autoHideDuration,
        multiLine,
        ...rest
    } = props;
    const translate = useTranslate();
    const dispatch = useDispatch();
    const notification = useSelector(getNotification);
    const inputRef = useRef(null);

    useEffect(() => {
        notification && openNotification(notification.type)
        return () => {
            message.destroy('notification')
        }
    }, [notification]);

    const openNotification = (notifcationType: NoticeType) => {
        message.open({
            type: notifcationType,
            key: 'notification',
            onClose: handleExited,
            content: translate(notification.message)
        })
      };
    
      const handleExited = useCallback(() => {
        if (notification) {
            dispatch(hideNotification());
        }
    }, [dispatch, notification]);


    return (
        null
    );
};

Notification.propTypes = {
    type: PropTypes.string,
    autoHideDuration: PropTypes.number,
    multiLine: PropTypes.bool,
};

Notification.defaultProps = {
    type: 'info',
    autoHideDuration: 4000,
    multiLine: false,
};

export default Notification;