import React from "react"
import {useQuery} from "react-admin";
import {formatDateAndTimeFromNow} from "../../../helpers/utils";
import {Card, Spin, Table, Tabs} from "antd";
import SkeletonTable, {SkeletonTableColumnsType} from "../../Common/SkeletonTable";
import DeclarationDiff from "../../ChangeInstances/DeclarationDiff";
import TabPane = Tabs.TabPane;

const ChangeInstanceDetailCompact = ({ record, extraProps }: { record: Record<string, any>, extraProps: any }) => {
    const resource = extraProps.resource;

    const { data: historyData, loaded, error, loading } = useQuery({
        type: 'getAction',
        resource: resource,
        payload: { id: record.id, verb: 'history', filter: { }}
    });
    const spinnerElement = <Spin size="large" tip="Loading"><Card style={{height: "100px"}}></Card></Spin>

    const columnsHistory = [
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: 'Changed By',
            dataIndex: 'changed_by',
            key: 'changed_by',
        },
        {
            title: 'Modified',
            key: 'modified',
            render: (text: any, record: any) => formatDateAndTimeFromNow(record.modified),
        },
    ];
    return (
        <Tabs defaultActiveKey="1" type='card'>
            <TabPane tab="History" key="1">
                {
                    historyData?
                        <SkeletonTable columns={columnsHistory as SkeletonTableColumnsType[]}>
                            <Table
                                rowKey="modified"
                                columns={columnsHistory}
                                dataSource={historyData?.results}
                                pagination={false}
                            />
                        </SkeletonTable>
                        :
                        spinnerElement
                }
            </TabPane>
            <TabPane tab="Diff" key="2">
                <DeclarationDiff change_instance={record} />
            </TabPane>
        </Tabs>
    );
}

export default ChangeInstanceDetailCompact