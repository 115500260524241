import React, { createContext, useContext, useState } from 'react';

interface BuilderWizardContextType {
    setSelectedService: Function;
    selectedService: string | null;
    setServiceItemsData: Function;
    serviceItemsData: Data | null;
    setValidationErrors: Function;
    validationErrors: Data | null;
    setDeclarationData: React.Dispatch<React.SetStateAction<Data | null>>;
    declarationData: Data | null;
    selectedApplication: string | null;
    setSelectedApplication: React.Dispatch<React.SetStateAction<string | null>>;
    isCurrentStepValid: Boolean;
    setIsCurrentStepValid: React.Dispatch<React.SetStateAction<boolean>>;
    currentStep: number;
    setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
    
}

interface ApplicationSelectItem {
    name: string;
}

interface Data {
    [key: string]: any;
}

// Create a context to manage shared data between wizard steps
const BuilderWizardContext = createContext<BuilderWizardContextType>({} as BuilderWizardContextType);

export const useBuilderWizardContext = () => {
    return useContext(BuilderWizardContext);
};

export const BuilderWizardProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [selectedApplication, setSelectedApplication] = useState<string | null>(null);
    const [selectedService, setSelectedService] = useState(null);
    const [serviceItemsData, setServiceItemsData] = useState<Data | null>(null);
    const [declarationData, setDeclarationData] = useState<Data | null>(null);
    const [validationErrors, setValidationErrors] = useState<Data | null>(null);
    const [isCurrentStepValid, setIsCurrentStepValid] = useState(false); 
    const [currentStep, setCurrentStep] = useState(0);

    const context: BuilderWizardContextType = {
        selectedApplication,
        serviceItemsData,
        setServiceItemsData,
        setSelectedApplication,
        setSelectedService,
        selectedService,
        isCurrentStepValid,
        setIsCurrentStepValid,
        setCurrentStep,
        currentStep,
        setDeclarationData,
        declarationData,
        validationErrors,
        setValidationErrors
    }
    return (
        <BuilderWizardContext.Provider
            value={context}
        >
            {children}
        </BuilderWizardContext.Provider>
    );
};