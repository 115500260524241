import { ClockCircleOutlined, ExclamationCircleFilled, SyncOutlined } from "@ant-design/icons";
import { faRocket, faThLarge, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Alert, Button, Card, Col, Row, Space, Spin, Tabs, Tag, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import { useDataProvider, useEditContext, useNotify, usePermissions } from "react-admin";
import { ExtendedDataProvider, ServiceItemDetailProps } from '../../types';
import { HealthCheckResponsesColumns } from "../Common/Columns";
import ResponseListView from "../Common/HTTPResponseList";
import { getTagForChangeStatus, getTagForResponsesStatus, getTagForRuntimeStates } from "../Common/Tags";
import VersionizedObjectsTab from "../Common/VersionizedObjectsTab";
import FaIcon from "../Icons/FaIcon";
import ChangeInstancesTab from "./SubComponents/ChangeInstancesTab";
import Declarations from "./SubComponents/Declarations";
import Metadata from "./SubComponents/Metadata";
const { Text, Title } = Typography
import TabPane = Tabs.TabPane;
import { formatDateAndTime, formatDateAndTimeFromNow } from "../../helpers/utils";

const ServiceItemDetailCard = ({ pov, serviceItemsResource, changeInstancesResource, deployedItemsResource, healthcheckResource, healthcheckResponsesResource }: ServiceItemDetailProps) => {
    const { record } = useEditContext<any>();
    const [healthCheckTriggered, setHealthCheckTriggered] = useState(false)
    const dataProvider: ExtendedDataProvider = useDataProvider();
    const { permissions } = usePermissions();
    const notify = useNotify();

    const triggerHealthcheckForServiceItem = (record: any) => {
        setHealthCheckTriggered(true);
        dataProvider.getAction(healthcheckResource, { verb: "trigger/service_item/" + record.id })
            .then((response: any) => {
                notify('Healthcheck triggered successfully')
                setHealthCheckTriggered(false)
            }).catch((error: any) => {
                notify('Error triggering healthcheck', 'error')
                setHealthCheckTriggered(false)
            })
    }

    const styleRow = { padding: '2px', height: "25px" }
    const permissionChangeState = ["ADMIN", "GENERAL"].includes(permissions);
    return (
        record ?
            <Card style={{ padding: '24px' }} className=" header-solid h-full ant-card pt-0">
                <Row>
                    {
                        record.is_service_private ?
                            <Alert
                                message="Service has become private"
                                description="The service has become private and your team is not in the service's allowed teams list"
                                type="error"
                                style={{ marginBottom: "5px", width: "100%" }}
                            />
                            :
                            <></>
                    }
                </Row>
                <Row>
                    <Col
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                        span={24}
                    >
                        <Space align="center">
                            {record.created && <Tooltip title={formatDateAndTime(record.created)}><Text className='text-sm'>Created {formatDateAndTimeFromNow(record.created)}</Text></Tooltip>}
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Title level={3}>{record.name}</Title>
                    </Col>
                    <Col
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                        span={16}
                    >
                        <Space>
                            {getTagForRuntimeStates(record.runtime_state)}
                            {getTagForChangeStatus(record.change_state)}
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ paddingRight: '6px' }}>
                        <Row gutter={2} align="middle">
                            <Col style={{ display: 'flex' }} className='text-sm'>{FaIcon(faRocket)}</Col>
                            <Col>
                                {
                                    record.is_deprecated_service_schema ?
                                        <Tooltip title="The service has a new schema" color={"orange"} placement={"topRight"}>
                                            <Typography.Text className='text-sm' type={'secondary'}>{record.service.name}</Typography.Text>
                                            {" "}
                                            <ExclamationCircleFilled style={{ color: 'orange' }} />
                                        </Tooltip>
                                        :
                                        <Typography.Text className='text-sm' type={'secondary'}>{record.service.name}</Typography.Text>

                                }
                            </Col>
                        </Row>
                    </Col>

                    <Col style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                        <Row gutter={2} align="middle">
                            <Col style={{ display: 'flex' }} className='text-sm'>{FaIcon(faThLarge)}</Col>
                            <Col className='text-sm'><Typography.Text type={'secondary'}>{record.application.name}</Typography.Text></Col>
                        </Row>
                    </Col>
                    {
                        pov === "serviceowner" &&
                        <>
                            <Col style={{ paddingLeft: '6px' }}>
                                <Row gutter={2} align="middle">
                                    <Col style={{ display: 'flex' }} className='text-sm'>{FaIcon(faUsers)}</Col>
                                    <Col className='text-sm'><Typography.Text type={'secondary'}>{record.consumer_team.name}</Typography.Text></Col>
                                </Row>

                            </Col>
                        </>
                    }

                </Row>

                {
                    record?.service?.healthcheck &&
                    <Row>
                        <Col style={{ textAlign: 'right' }} flex='auto'>
                            <Space style={{ textAlign: 'right' }} >
                                <Tooltip title="Last Healthcheck Response">
                                    {getTagForResponsesStatus(record.healthcheck_status)}
                                </Tooltip>
                                {
                                    permissionChangeState &&
                                    <Tooltip title="Trigger Healthcheck">
                                        <Button size="small" type="text" loading={healthCheckTriggered} onClick={() => triggerHealthcheckForServiceItem(record)} icon={<SyncOutlined />} />
                                    </Tooltip>
                                }
                            </Space>
                        </Col>
                    </Row>
                }
                <Row gutter={16} style={{ paddingTop: '10px' }}>
                    <Col className="gutter-row" span={24}>
                        <Card>
                            <Tabs defaultActiveKey="1" >
                                <TabPane tab="Declarations" key="1">
                                    <Declarations
                                        resource={serviceItemsResource}
                                        service_item={record}
                                    />
                                </TabPane>
                                <TabPane tab="Change Instances" key="2">
                                    <ChangeInstancesTab
                                        resource={changeInstancesResource}
                                        baseFilter={{ service_item_id: record.id }}
                                        submissionPageRedirectVisible={pov === "consumer"}
                                    />
                                </TabPane>

                                <TabPane tab="Deployed Items" key="3">
                                    <VersionizedObjectsTab
                                        resource={deployedItemsResource}
                                        light={true}
                                        name={"deployed item"}
                                        attrName={"data"}
                                        isCreate={pov === "serviceowner" && permissions === "ADMIN"}
                                        record={record}
                                    />
                                </TabPane>
                                {
                                    record?.service?.healthcheck &&
                                    <TabPane tab="Healthcheck Responses" key="4">
                                        <ResponseListView
                                            columns={HealthCheckResponsesColumns}
                                            resource_name={healthcheckResponsesResource}
                                            filters={{ service_item_id: record.id }}
                                        />
                                    </TabPane>
                                }
                                <TabPane tab="Metadata" key="5x">
                                    <Metadata record={record} />
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Col>
                </Row>
            </Card>
            :
            <Spin style={{ 'width': '100%' }} />
    )
}

export default ServiceItemDetailCard;
