import React, {useEffect, useState} from 'react'
import LoginForm from './LoginForm'
import Notification from '../Layout/Notification'
import { NavLink, useLocation, useHistory } from "react-router-dom";
import {
     Col, Typography,
    Layout, Row, Menu, Button
} from "antd";
// @ts-ignore
import logo from '../../assets/img/netorca.png'
import { useCheckAuth } from 'ra-core';
import LoginGoogle from "./LoginGoogle";
import {useDataProvider} from "react-admin";
import {ExtendedDataProvider} from "../../types";
import { Helmet } from 'react-helmet';
const { Title } = Typography;

const { Header, Content } = Layout;

const LoginPage = () => {
    const dataProvider: ExtendedDataProvider = useDataProvider()
    const [googleOAuthEnabled, setGoogleOAuthEnabled] = useState<boolean>(false);
    const checkAuth = useCheckAuth();
    const history = useHistory();
    useEffect(() => {
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                history.push('/');
            })
            .catch(() => {
                // not authenticated, stay on the login page
            });
    }, [checkAuth, history]);

    useEffect(() => {
        dataProvider.getAction('general_settings',
          { verb: 'everyone/',  filter: {"name": "GoogleOAuth"} })
          .then((response: any) => {
              if(response.data?.count == 1 && process.env.GOOGLE_OAUTH_CLIENT_ID){
                  setGoogleOAuthEnabled(response.data.results[0]?.config?.ENABLED)
              }
          })
          .catch((error) => {
              console.error(error)
          })

    }, [])


    return (
        <>
            <Layout className="layout-default layout-signin">
            <Helmet>
                <title>Sign In - NetOrca</title>
            </Helmet>
                <Header>
                    <div className="">
                        <img height='100%' src={logo} alt="" />
                    </div>


                </Header>
                <Content className="">
                    <Row gutter={[24, 0]} justify="space-around">
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            lg={{ span: 6, offset: 2 }}
                            md={{ span: 12 }}
                        >
                            <Title className="mb-15">Sign In</Title>
                            <Title className="font-regular text-muted" level={5}>
                                Enter your username and password to sign in
                            </Title>
                            <LoginForm />
                            {googleOAuthEnabled && <LoginGoogle />}
                        </Col>

                    </Row>
                </Content>
                <Notification />

            </Layout>
        </>
    )
}

export default LoginPage;