import { Card, Table, Tabs } from 'antd';
import React, { useState } from 'react';
import { useQueryWithStore } from 'react-admin';
import ReactJson from 'react-json-view';
import SkeletonTable, { SkeletonTableColumnsType } from '../Common/SkeletonTable';
const { TabPane } = Tabs;

const DependantServices = () => {
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(20);
    const { loading, error, data, total } = useQueryWithStore({
        type: 'getList',
        resource: 'dependant_services',
        payload: {
            pagination: { page: page, perPage: perPage },
            sort: { field: 'name', order: 'asc' },
            filter: {}
        }
    });

    const expandedRowRender = (record: any) => {
        return (
            <Tabs defaultActiveKey="1" type='card'>
                <TabPane tab="Declaration" key="1">
                    <Card>
                        <ReactJson style={{ padding: '20px' }} src={record.schema} displayDataTypes={false} name="schema" displayArrayKey={false} displayObjectSize={false} />
                    </Card>
                </TabPane>

            </Tabs>
        )
    }
    const columns = [
        {
            title: 'Service Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Owning Team',
            dataIndex: ['owner', 'name'],
            key: 'owner',
        },
    ]

    return (

        <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
            <Table
                rowKey='id'
                columns={columns}
                pagination={{ 
                    pageSizeOptions:[25,50,100],
                    onShowSizeChange: (_current, size) => setPerPage(size), showSizeChanger: true,
                    total: total, pageSize: perPage, current: page, onChange: page => setPage(page) }}
                dataSource={data}
                expandable={{ expandedRowRender, expandRowByClick: true }}
            />
        </SkeletonTable>

    )
}



export default DependantServices;
