import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const FaIcon = (icon: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 20"
    preserveAspectRatio="xMidYMid meet"
  >
    <FontAwesomeIcon icon={icon} />
  </svg>
)
export default FaIcon;
