import { useSelector } from 'react-redux';
import { getResources } from 'react-admin';
import { useLocation } from 'react-router-dom';

const useGetCurrentResource = (): string => {
    let { pathname } = useLocation();
    const resources = useSelector(getResources);
    if(pathname == '/') {
        return 'Dashboard'
    }
    const splitPath = pathname.replace(/[\/\\]/, '').split('/')[0];
        for (let resource of resources) {
            if (resource.name == splitPath) {
                return resource?.options?.label ?? splitPath.replace("/", "").replace("_", " ").replace(/(^\w|\s\w)/g, (m: string) => m.toUpperCase())
            }
        }
    return ''
}
export default useGetCurrentResource;