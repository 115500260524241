import {Card, Row, Space, Typography} from "antd";
import React from 'react';
import {getCurrency} from "../../Common/utils";
import {PriceTag} from "../../Common/Tags";
const { Title, Text } = Typography;

const TotalChargesWidget = ({ data }: { data: any }) => {
    const currency = getCurrency()

    return (
        <Card bordered={false} className="criclebox ">
            <Row>
                <Title style={{ margin: '20px' }} level={5}>Summary</Title>
            </Row>
            <div className="number" style={{ margin: '20px' }}>
                <Space direction={'horizontal'} size={"large"}>
                    <span>
                        Number of charges
                        <Title level={3}>{data.num_of_charges || 0}</Title>
                    </span>
                    <span>
                        Total sum
                        <Title level={3}><PriceTag amount={data.sum_total_charge || 0} currency={currency}/></Title>
                    </span>
                    <span>
                        Total processed
                        <Title level={3}><PriceTag amount={data.sum_total_processed || 0} currency={currency}/></Title>
                    </span>
                </Space>
            </div>
        </Card>
    )
}

export default TotalChargesWidget;