
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import FaIcon from '../Icons/FaIcon';

const TimelineItemRow = ({ icon, text }: { icon: IconDefinition, text: string }) => (
    <div>
        <span style={{  color: '#8c8c8c', width: '10px', verticalAlign: 'middle', paddingRight: '5px' }}>{FaIcon(icon)}</span>
        <span style={{ verticalAlign: 'middle' }}>{text}</span>
    </div>
)

export default TimelineItemRow