import React, { useEffect } from 'react'
import { GoogleLogin } from '@react-oauth/google';
import { useRedirect } from "react-admin";
import {useCheckAuth } from "ra-core";
import {useHistory} from "react-router-dom";
import { useAuthProvider } from 'react-admin';


const LoginGoogle = () => {
    const authProvider = useAuthProvider();
    const redirect = useRedirect();
    const checkAuth = useCheckAuth();
    const history = useHistory();

    useEffect(() => {
        checkAuth({}, false)
          .then(() => {
              // already authenticated, redirect to the home page
              history.push('/');
          })
          .catch(() => {
              // not authenticated, stay on the login page
          });
    }, [checkAuth, history]);

    async function handleCredentialResponse(response: any) {
        if (response.credential) {
            await authProvider.loginGoogle(response.credential)
            redirect('/')
        }
    }
    return (
      <GoogleLogin
        onSuccess={(credentialResponse: any) => {handleCredentialResponse(credentialResponse)}}
        onError={() => {console.log('Login Failed');}}
      />
    )
}

export default LoginGoogle
