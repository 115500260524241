import * as React from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { useNotify, useDataProvider, useRedirect } from 'ra-core';

const RegistrationForm = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [form] = Form.useForm();
    const dataProvider = useDataProvider();
    const onSubmit = (values: any) => {
        //@ts-ignore
        dataProvider.create('users', { data: values, idField: 'username' })
            .then(data => {
                notify('User created successfully');
                redirect('/login')
            })
            .catch((error) => {
                if(error.status == 400) {

                    
                    for (const [key, value] of Object.entries<any>(error.body))
                    {
                        form.setFields([
                            { name: key, errors: value}
                        ])
                    }
                }
            })
    };

    return (
        <Form
            form={form}
            name="normal_login"
            className="row-col"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
        >
            <Form.Item
                name="first_name"
                rules={[{ required: true, message: 'Please input your First Name' }]}
            >
                <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
                name="last_name"
                rules={[{ required: true, message: 'Please input your Last Name' }]}
            >
                <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please input your Email' }]}
            >
                <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
            </Form.Item>
            <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your Username' }]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password' }]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                />

            </Form.Item>
            <Form.Item
                name="password2"
                rules={[{ required: true, message: 'Please enter password confirmation' }]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Confirm Password"
                />

            </Form.Item>
            <Form.Item>
                <Button style={{ width: "100%" }} type="primary" htmlType="submit" className="login-form-button">
                    Sign up
                </Button>
            </Form.Item>
        </Form>
    )
}

export default RegistrationForm;