import {Button, Form, Input, Checkbox, Space, Divider} from "antd";
import React, { useState, useEffect } from "react";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'

import cronstrue from 'cronstrue';
import {usePermissions} from "react-admin";

const CrontabForm = (props: any) => {
    const { permissions } = usePermissions();
    const { form } = props
    const [cronText, setCronText] = useState<string>("* * * * *");
    const [enableCrontab, setEnableCrontab] = useState(false);

    useEffect(() => {
        // Set initial value for schedule_enabled
        const initialScheduleEnabled = form.getFieldValue("schedule_enabled")
        const initialScheduleCrontab = form.getFieldValue("schedule_crontab")
        if(initialScheduleEnabled)
            setEnableCrontab(initialScheduleEnabled)
        if(initialScheduleCrontab)
            setCronText(initialScheduleCrontab)
    }, []);

    useEffect (() => {
        // To sync enableCrontab and schedule_enabled in the form
        form.setFieldsValue ({"schedule_enabled": enableCrontab})

        if(!enableCrontab)
            form.setFieldsValue ({"schedule_crontab": null})
        else
            form.setFieldsValue ({"schedule_crontab": cronText})

    }, [enableCrontab]);

    const parseCronText = (text: string) => {
        try { return <>Final: {cronstrue.toString(text, { locale: "en" })}</> }
        catch (e) { return <>Wrong crontab</> }
    }
    const onChange = (e: CheckboxChangeEvent) => {
        setEnableCrontab(e.target.checked);
    };

    useEffect(() => {
        // if cron text is changed, its value in the form should also be changed
        form.setFieldsValue({"schedule_crontab": cronText})
    }, [cronText]);

  return (
    <>
      <Form.Item label="Enable Crontab" name="schedule_enabled">
          <Checkbox value={enableCrontab} checked={enableCrontab} onChange={onChange} disabled={permissions !== "ADMIN"} />
      </Form.Item>
      <Form.Item
        hidden={!enableCrontab}
        label="Schedule Crontab"
        extra={parseCronText(cronText)}
        name="schedule_crontab"
        rules={[
          {
            validator: async (_, value) => {
              var cronRegex = new RegExp(/^(\*|([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])|\*\/([0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])) (\*|([0-9]|1[0-9]|2[0-3])|\*\/([0-9]|1[0-9]|2[0-3])) (\*|([1-9]|1[0-9]|2[0-9]|3[0-1])|\*\/([1-9]|1[0-9]|2[0-9]|3[0-1])) (\*|([1-9]|1[0-2])|\*\/([1-9]|1[0-2])) (\*|([0-6])|\*\/([0-6]))$/);
              if (enableCrontab && !cronRegex.test(value)) {
                return Promise.reject(new Error('Invalid Cron Expression'));
              }
            },
          },
        ]}
      >
          <Input value={cronText} onChange={(e) => setCronText(e.target.value)} placeholder="* * * * *" disabled={permissions !== "ADMIN"}/>
      </Form.Item>
      <Form.Item hidden={!enableCrontab} label="Crontab generator">
          <Cron value={cronText} setValue={setCronText} clearButton={false} disabled={permissions !== "ADMIN"}/>
      </Form.Item>
    </>
  )
}

export default CrontabForm;