import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Switch } from 'antd';
import { useDataProvider, useNotify } from 'react-admin';
import {ExtendedDataProvider} from "../../types";

const GoogleOAuthSettingsForm = () => {
    const notify = useNotify()
    const [currentSettings, setCurrentSettings] = useState<any>({});
    const [loadedSettings, setLoadedSettings] = useState<boolean>(false);
    const [form] = Form.useForm();
    const dataProvider: ExtendedDataProvider = useDataProvider()

    useEffect(() => {
        dataProvider.getOne('general_settings', {id: "GoogleOAuth"})
          .then((data: any) => {
              setCurrentSettings(data?.data)
              setLoadedSettings(true)
          }).catch((error: any) => {
            if (error.status == 404) {
                setCurrentSettings({})
                setLoadedSettings(true)
            } else {
                console.log(error)
            }
        })
    }, [])

    if (!loadedSettings) { return null }

    const onSubmit = (values: any) => {
        const submission = {
            "name": "GoogleOAuth",
            "config": values
        }
        if (currentSettings && Object.keys(currentSettings).length != 0) {
            // update existing
            dataProvider.update('general_settings', {
                id: currentSettings.id,
                data: submission,
                previousData: {id: currentSettings.id}
            })
              .then(response => {
                  notify('Settings have been saved.')
              }).catch(error => {
                notify("Something went wrong", "warning")
            })
        } else {
            //create
            dataProvider.create('general_settings', { data: submission })
              .then(response => {
                  setCurrentSettings(response?.data)
                  notify('Settings have been saved.')
              }).catch(error => {
                console.error(error)
                notify("Something went wrong", "warning")
            })
        }
    }

    return (
      <>
          <Form form={form}
                labelAlign='left'
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{
                    ENABLED: currentSettings?.['config']?.ENABLED
                }}
                onFinish={onSubmit}>

              <Form.Item valuePropName='checked' label="Enable GoogleOAuth" name="ENABLED" initialValue={currentSettings?.['config']?.ENABLED || false}>
                  <Switch defaultChecked={currentSettings?.['config']?.ENABLED == true} />
              </Form.Item >

              <Form.Item
                label="Client ID"
                initialValue={currentSettings?.['config']?.CLIENT_ID}
                name="CLIENT_ID"
                rules={[{ required: true, message: 'Please input client id' }]}
              >
                  <Input />
              </Form.Item>

              <Form.Item
                label="Client SECRET"
                initialValue={currentSettings?.['config']?.CLIENT_SECRET}
                name="CLIENT_SECRET"
                rules={[{ required: true, message: 'Please input client secret' }]}
              >
                  <Input />
              </Form.Item>

              <Form.Item >
                  <Button type="primary" htmlType="submit" className="form-button">
                      Save Changes
                  </Button>
              </Form.Item>
          </Form>
      </>
    )
}

export default GoogleOAuthSettingsForm;
