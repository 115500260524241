import { Breadcrumb } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import useGetCurrentResource from './useGetCurrentResource';

const Breadcrumbs = ({ path }: { path: string }) => {
    const currentResource = useGetCurrentResource();
    const splitPaths = path.replace(/[\/\\]/, '').split('/').filter(segment => segment !== '');
    if (splitPaths.length < 1) {
        return <></>
    }
    return (
        <Breadcrumb separator="/">
            <Breadcrumb.Item>
                <NavLink to="/">Home</NavLink>
            </Breadcrumb.Item>
            {splitPaths.map((subPath, idx) => {
                let crumbName = idx == 0 ? currentResource : subPath
                if (splitPaths.length == idx + 1) {
                    return <Breadcrumb.Item key={idx}>{crumbName}</Breadcrumb.Item>
                }
                return <Breadcrumb.Item><NavLink to={`/${subPath}`}>{crumbName}</NavLink></Breadcrumb.Item>
            })
            }
        </Breadcrumb>
    )
}

export default Breadcrumbs;