import {Card, Form, Table, Tag} from 'antd';
import React, { useState } from 'react';
import {useGetIdentity, useQuery} from 'react-admin';
import SkeletonTable, { SkeletonTableColumnsType } from '../Common/SkeletonTable';

const TeamMembershipList = () => {
  const { identity } = useGetIdentity();
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const { loading, error, data, total, refetch } = useQuery({
    type: 'getList',
    resource: 'team_memberships',
    payload: {
      pagination: { page: page, perPage: perPage },
      sort: { field: 'created', order: 'asc' },
      filter: {}
    }
  });

  const columns = [
    {
      title: 'Username',
      key: 'username',
      render: (record: any) => (
        <span>
          {record.username}
          {identity?.username == record.username && <Tag style={{ marginLeft: '10px' }} color="green">You</Tag>}
        </span>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
  ]

  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      title="Team Members"
    >
      <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
        <Table
          rowKey="id" columns={columns} dataSource={data && data.filter((member: any) => member.role != 'CONFIGURATION')}
          pagination={{
            pageSizeOptions: [25, 50, 100],
            onShowSizeChange: (_current, size) => setPerPage(size), showSizeChanger: true,
            total: total, pageSize: perPage, current: page, onChange: page => setPage(page)
          }}
        />
      </SkeletonTable>
    </Card>
  )
}

export default TeamMembershipList;
