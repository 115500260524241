import {ArrayFieldTemplateProps} from "@rjsf/utils";
import React from "react";
import {Button} from "antd";
import {DeleteOutlined, PlusCircleOutlined} from "@ant-design/icons";


const CustomArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
    return (
        <div>
            {props.items && props.items.map((element) => (
                <div key={element.index} style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1, marginBottom: "15px" }}>
                        {element.children}
                    </div>
                    <Button
                        danger
                        type={"primary"}
                        shape="circle"
                        icon={<DeleteOutlined />}
                        className={"submission-builder-del-btn"}
                        style={{ marginLeft: "10px"}}
                        onClick={element.onDropIndexClick(element.index)}
                    />
                </div>
            ))}
            {
                props.canAdd &&
                (
                    <Button
                        type={"primary"}
                        shape="circle"
                        onClick={props.onAddClick}
                        style={{ marginTop: '0px' }}
                        icon={<PlusCircleOutlined />}
                    >
                        {props.title}
                    </Button>
                )
            }
        </div>
    );
};

export default CustomArrayFieldTemplate
