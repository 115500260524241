import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Table, Tabs, Tag, Switch } from 'antd';
import * as React from 'react';
import {
  ListBase, ListProps, Record, useDataProvider, useListContext, useNotify, usePermissions
} from 'react-admin';
import { Link } from 'react-router-dom';
import { ExtendedDataProvider } from '../../types';
import SkeletonTable, { SkeletonTableColumnsType } from '../Common/SkeletonTable';
import ResponseListView from "../Common/HTTPResponseList";
import {formatDateAndTime} from "../../helpers/utils";

const { TabPane } = Tabs;


const ListView = () => {
  const { ids, data, loading, total, perPage, setPage, page } = useListContext();
  const dataProvider: ExtendedDataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions } = usePermissions();

  const triggerWebhook = (record: any) => {
    dataProvider.createAction('webhooks', { data: {}, id: record.id, verb: 'trigger/' })
      .then((response: any) => {
        notify('Webhook triggered successfully')
      }).catch((error: any) => {
        notify(JSON.stringify(error.body), 'error')
      })
  }
  const deleteWebhook = (record: any) => {
    dataProvider.delete('webhooks', { id: record.id })
      .then(response => {
        notify('Webhook deleted')
        // redirect('/webhooks')
      }).catch(error => {
        notify("Something went wrong", "warning")
      })
  }
  const enableWebhook = (record: any, checked: boolean) => {
    dataProvider.update('webhooks', {
      id: record.id,
      data: {
        active: checked ,
        service: record.service.id,
        target_url: record.target_url
      },
      previousData: { id: record.id }
    })
      .then(data => {
        record.active = checked
        notify('Webhook ' + (record.active? "Enabled": "Disabled"));
      })
      .catch((error: any) => {
        if (error.status == 400) {
          notify('Error updating webhook', 'error')
        }
      })
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Service',
      dataIndex: ['service', 'name'],
      key: 'service',
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
    },
    {
      title: 'Target URL',
      dataIndex: 'target_url',
      key: 'target_url',
      render: (text: any, record: any) => <div title={text} style={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</div>
    },
    {
      render: (text: any, record: any) =>
        <div>
          {
              permissions === "ADMIN" &&
              <Button style={{ marginRight: '5px'}} onClick={() => triggerWebhook(record)}>
                Trigger
              </Button>
          }
          <Button style={{ marginRight: '5px'}}>
            <Link to={`/webhooks/${record.id}/edit`}><EditOutlined />{permissions === "ADMIN"?"Edit":"Show"}</Link>
          </Button>
          {
            permissions === "ADMIN" &&
            <Switch
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              defaultChecked={record.active}
              onChange={(checked) => enableWebhook(record, checked)}
            />
          }
        </div>
    },

  ]

  const tableData: Record[] = []
  ids.forEach(id => {
    tableData.push(data[id])
  });
  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      extra={permissions === "ADMIN" && <Link to="/webhooks/create"><Button type='primary'>Create Webhook</Button></Link>}
      title="Webhooks"
    >

      <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
        <Table
          rowKey='id'
          columns={columns}
          pagination={{ total: total, pageSize: perPage, current: page, onChange: page => setPage(page) }}
          dataSource={tableData}
        />
      </SkeletonTable>
    </Card>
  )
}


const responses_columns = [
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Status Code',
    dataIndex: 'status_code',
    key: 'status_code',
  },
  {
    title: 'Webhook',
    dataIndex: ['webhook', 'name'],
    key: 'webhook',
  },
  {
    title: 'created',
    key: 'created',
    render: (text: any, record: any) => formatDateAndTime(record.created)
  },
];


const WebhookList = (props: ListProps) => (
  <Tabs
    defaultActiveKey="1"
    style={{ display: "flex", justifyContent: "space-between" }}
  >
    <TabPane tab="Webhooks" key="1" >
      <ListBase {...props}>
        <ListView />
      </ListBase>
    </TabPane>
    <TabPane tab="Responses" key="2">
      <ResponseListView
        columns={responses_columns}
        resource_name={"webhook_responses"}
        filters={{}}
      />
    </TabPane>
  </Tabs>
)

export default WebhookList;
