import {useDataProvider, useNotify, useQueryWithStore, useRefresh} from "react-admin";
import React from "react";
import {Button, Form, Switch} from "antd";
import {ExtendedDataProvider} from "../../types";

const SubmissionBuilderSettingsForm = () => {
    const notify = useNotify ()
    const [form] = Form.useForm ();
    const refresh = useRefresh();
    const dataProvider: ExtendedDataProvider = useDataProvider ()
    const settingsName = "SubmissionBuilder"

    const {loaded, error, data: currentSettings} = useQueryWithStore ({
        type: 'getOne',
        resource: 'general_settings',
        payload: {id: settingsName}
    });

    const onSubmit = (values: any) => {
        const submission = { "name": settingsName, "config": values}

        if (currentSettings && Object.keys(currentSettings).length != 0) {
            // update existing
            dataProvider.update('general_settings', {
                id: currentSettings.id,
                data: submission,
                previousData: {id: currentSettings.id}
            })
                .then(response => notify('Settings have been saved.'))
                .catch(error => notify("Something went wrong", "warning"))
        } else {
            //create
            dataProvider.create('general_settings', { data: submission })
                .then(response => {
                    refresh()
                    notify('Settings have been saved.')
                }).catch(error => {
                console.error(error)
                notify("Something went wrong", "warning")
            })
        }
    }

    return (
        <>
            {
                (loaded || error) &&
                <Form
                    form={form}
                    labelAlign='left'
                    labelCol={{span: 10}}
                    wrapperCol={{span: 16}}
                    onFinish={onSubmit}
                >
                    <Form.Item
                        label={"Show Submission Builder"}
                        initialValue={currentSettings?.['config']?.show_submission_builder || false}
                        name="show_submission_builder"
                        rules={[{required: true}]}
                    >
                        <Switch defaultChecked={currentSettings?.['config']?.show_submission_builder == true}/>
                    </Form.Item>

                    <Form.Item
                        label={"Enable Submit Functionality"}
                        initialValue={currentSettings?.['config']?.enable_submit_functionality || false}
                        name="enable_submit_functionality"
                        rules={[{required: true}]}
                    >
                        <Switch defaultChecked={currentSettings?.['config']?.enable_submit_functionality == true}/>
                    </Form.Item>

                    <Form.Item
                        label={"Default Allow Submit"}
                        initialValue={currentSettings?.['config']?.default_allow_submit_submission_builder || false}
                        name="default_allow_submit_submission_builder"
                        rules={[{required: true}]}
                    >
                        <Switch defaultChecked={currentSettings?.['config']?.default_allow_submit_submission_builder == true}/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="form-button">
                            Save Changes
                        </Button>
                    </Form.Item>
                </Form>
            }
        </>
    )
}

export default SubmissionBuilderSettingsForm;
