import {ExtendedDataProvider, GeneralFilterProps, SelectItem} from "../../types";
import { useDataProvider } from "react-admin";
import React, {useEffect, useState} from "react";
import DebounceSelect from "../Common/DebounceSelect";
import {Form} from "antd";

const ServiceTagFilter =  ({ filterValues , setFilterValues }: GeneralFilterProps) => {
    const [selectedTags, setSelectedTags] = useState<any>([]);

    const dataProvider: ExtendedDataProvider = useDataProvider();

    const fetchTagList = async (values: string): Promise<any> => {
        return dataProvider.getAction("browse_services", {
            verb: 'tags',
            filter: {
                'name': values
            }
        })
            .then((data: any) => {
                return data.data.results.map((result: any) => ({
                    label: result.name,
                    value: result.name
                }))
            })
            .catch((error: any) => console.log(error))
    }
    useEffect(() => {
        setFilterValues({ ...filterValues, 'tags': selectedTags.join() })
    }, [selectedTags])


    return (
        <Form className="row-col">
            <Form.Item
            >
        <DebounceSelect
            createable={false}
            mode="multiple"
            showSearch
            placeholder="Select Tags"
            fetchOptions={fetchTagList}
            value={selectedTags}
            onChangeSelect={newValue => {
                // @ts-ignore
                setSelectedTags(newValue.map((tag: any) => tag.value));
            }}
            style={{ display: 'block' }}
        />
            </Form.Item>
        </Form>
    )

}



export default ServiceTagFilter;

