import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, Switch } from 'antd';
import { useDataProvider, useNotify } from 'react-admin';
const { Title } = Typography;

const LdapSettingsForm = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify()
    const [currentLdapSettings, setCurrentLdapSettings] = useState<any>({});
    const [loadedLdapSettings, setLoadedLdapSettings] = useState<boolean>(false);
    const [form] = Form.useForm();

    const onSubmit = (values: any) => {
        const submission = {
            "name": "LDAP",
            "config": values
        }
        if (currentLdapSettings && Object.keys(currentLdapSettings?.['config']).length != 0) {
            // update existing
            dataProvider.update('general_settings', { id: currentLdapSettings.id, data: submission, previousData: { id: currentLdapSettings.id } })
                .then(response => {
                    notify('Settings have been saved.')
                }).catch(error => {
                    notify("Something went wrong", "warning")
                })
        } else {
            //create
            dataProvider.create('general_settings', { data: submission })
                .then(response => {
                    setCurrentLdapSettings(response?.data)
                    notify('Settings have been saved.')
                }).catch(error => {
                    console.error(error)
                    notify("Something went wrong", "warning")
                })
        }
    }

    useEffect(() => {
        dataProvider.getList('general_settings', {
            pagination: { page: 1, perPage: 20 },
            sort: { field: "name", order: "asc" },
            filter: {
                'name': "LDAP"
            }
        }
        ).then((data: any) => {
            setCurrentLdapSettings(data?.data[0])
            setLoadedLdapSettings(true)
        }).catch((error: any) => {
            console.error(error)
        })
    }, [])

    if (!loadedLdapSettings) {
        return null;
    }


    return (
        <>
            <Form form={form}
                labelAlign='left'
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{
                    ENABLED: currentLdapSettings?.['config']?.ENABLED
                }}
                onFinish={onSubmit}>
                <Form.Item valuePropName='checked' label="Enable LDAP" name="ENABLED" initialValue={currentLdapSettings?.['config']?.ENABLED || false}>
                    <Switch defaultChecked={currentLdapSettings?.['config']?.ENABLED == true} />
                </Form.Item >
                <Form.Item
                    label="LDAP Host"
                    initialValue={currentLdapSettings?.['config']?.SERVER_URI}
                    name="SERVER_URI"
                    rules={[{ required: true, message: 'Please input Server URI' }]}
                >
                    <Input placeholder="ldap://ldap.organisation.com:389" />
                </Form.Item>
                <Form.Item
                    label="Bind DN"
                    initialValue={currentLdapSettings?.['config']?.BIND_DN}
                    name="BIND_DN"
                    rules={[{ message: 'Please input BIND_DN' }]}
                >
                    <Input placeholder="BIND_DN" />
                </Form.Item>
                <Form.Item
                    label="Bind Password"
                    initialValue={currentLdapSettings?.['config']?.BIND_PASSWORD}
                    name="BIND_PASSWORD"
                    rules={[{ message: 'Please input BIND_PASSWORD' }]}
                >
                    <Input.Password placeholder="BIND_PASSWORD" />
                </Form.Item>
                <Form.Item
                    label="User Search Base"
                    initialValue={currentLdapSettings?.['config']?.USER_SEARCH_BASE_DN}
                    name="USER_SEARCH_BASE_DN"
                    rules={[{ required: true, message: 'Please input USER_SEARCH_BASE_DN' }]}
                >
                    <Input placeholder="USER_SEARCH_BASE_DN" />
                </Form.Item>
                <Form.Item
                    label="Group Search Base"
                    initialValue={currentLdapSettings?.['config']?.GROUP_SEARCH_QUERY_BASE_DN}
                    name="GROUP_SEARCH_QUERY_BASE_DN"
                    rules={[{ required: true, message: 'Please input GROUP_SEARCH_QUERY_BASE_DN' }]}
                >
                    <Input placeholder="GROUP_SEARCH" />
                </Form.Item>
                <Form.Item >
                    <Button type="primary" htmlType="submit" className="form-button">
                        Save Changes
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default LdapSettingsForm;