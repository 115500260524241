import React, { useEffect } from 'react'
import RegistrationForm from './RegistrationForm'
import Notification from '../Layout/Notification'
import { NavLink, useLocation, useHistory } from "react-router-dom";
import {
    Card, Col, Typography,
    Layout, Row, Menu, Button
} from "antd";
// @ts-ignore
import logo from '../../assets/img/netorca.png'
import { useCheckAuth } from 'ra-core';
import { Helmet } from 'react-helmet';
const { Title } = Typography;

const { Header, Content } = Layout;

const RegistrationPage = () => {

    const checkAuth = useCheckAuth();
    const history = useHistory();
    useEffect(() => {
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                history.push('/');
            })
            .catch(() => {
                // not authenticated, stay on the login page
            });
    }, [checkAuth, history]);


    return (
        <>
            <Layout className="layout-default layout-signin">
            <Helmet>
                <title>Registration - NetOrca</title>
            </Helmet>
                <Header>
                    <div className="">
                        <img height='100%' src={logo} alt="" />
                    </div>
                </Header>
                <Content>
                    <Title style={{ textAlign: 'center' }} className="mb-15">Sign Up</Title>
                    <Card
                        className="card-signup header-solid h-full ant-card pt-0"
                        bordered={false}
                    >
                        <RegistrationForm />
                    </Card>
                </Content>
                <Notification />

            </Layout>
        </>
    )
}

export default RegistrationPage;