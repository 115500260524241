import { Layout, Row, Col } from "antd";
import React from "react";

function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter style={{ background: "#fafafa" }}>
      <Row className="just">
        <Col span={24}>
          <div className="version">{process.env.APP_VERSION ? process.env.APP_VERSION : ''}</div>
        </Col>
      </Row>
      <Row className="just">
        <Col span={24}>
          <div className="copyright">
            &copy; 2024 NetAutomate Limited
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
