import { Result } from 'antd';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

export const Error = ({
    error,
    resetErrorBoundary,
}: {error: any, resetErrorBoundary: Function}) => {
    const { pathname } = useLocation();
    const originalPathname = React.useRef(pathname);

    // Effect that resets the error state whenever the location changes
    React.useEffect(() => {
        if (pathname !== originalPathname.current) {
            resetErrorBoundary();
        }
    }, [pathname, resetErrorBoundary]);

    return (
        <div>
            <Result
                status="warning"
                title="Sorry, something went wrong."
            />
            
            {process.env.NODE_ENV !== 'production' && (
                <details>
                    <h2>{error.toString()}</h2>
                </details>
            )}
        </div>
    );
};