import { Button, Card, Divider, Form, Input, Switch, Typography } from 'antd';
import React from 'react';
import { Record, useDataProvider, useNotify, useQuery } from 'react-admin';
import useGetGeneralSettings from "../../providers/useGetGeneralSettings";
import {setActiveTeamLocalStorage} from "../Common/utils";


const TeamEdit = ({ team }: { team: any }) => {
  const { Title } = Typography;

  const dataProvider = useDataProvider();
  const notify = useNotify()
  const [form] = Form.useForm();

  const generalSettings = useGetGeneralSettings();
  const submissionBuilderSettings = generalSettings.find(setting => setting.name == 'SubmissionBuilder');
  const isVisibleSubmissionBuilder = submissionBuilderSettings?.config?.show_submission_builder == true;
  const isSubmitAllowedSubmissionBuilder = submissionBuilderSettings?.config?.enable_submit_functionality == true;

  const onSubmit = (values: any) => {
    if (isVisibleSubmissionBuilder && isSubmitAllowedSubmissionBuilder) {
      values['preferences'] = { 'allow_submit_submission_builder': values['allow_submit_submission_builder'] }
    }
    dataProvider.update('teams', { id: team.id, data: values, previousData: { id: team.id } })
      .then((response) => {
        setActiveTeamLocalStorage (response.data)
        notify('Team updated successfully');
      })
      .catch((error: any) => {
        if (error.status == 400) {
          for (const [key, value] of Object.entries<any>(error.body)) {
            form.setFields([
              { name: key, errors: value }
            ])
          }
        }
      })
  }

  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: '20px' }}
      className="criclebox tablespace mb-24"
      title="Team Settings"
    >
      <Form
        form={form}
        labelAlign='left'
        onFinish={onSubmit}
        className="row-col"
        initialValues={team}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: false }]}
          extra="If the email notification for your service is turned on, an email will be sent to this address each time there's a change instance generated for the service"
        >
          <Input placeholder="Email" />
        </Form.Item>
        
        {
          isVisibleSubmissionBuilder && isSubmitAllowedSubmissionBuilder &&
          <>
          <Divider />
          <Title level={5}>Submission Builder</Title>
          <Form.Item
            label={"Allow Submit"}
            initialValue={team['allow_submit_submission_builder']}
            name="allow_submit_submission_builder"
            rules={[{ required: false }]}
          >
            <Switch defaultChecked={team.preferences?.allow_submit_submission_builder} />
          </Form.Item>
          </>
        }
        <Form.Item>
          <Button style={{ width: "10%" }} type="primary" htmlType="submit" className="form-button">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default TeamEdit;