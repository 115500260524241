import {Button, Card, Form, Input, Select, Typography, Collapse, Modal} from "antd";
import ReactJson from 'react-json-view';
import React, {useEffect, useState} from "react";
import {
    Edit,
    EditBase,
    useRecordContext,
    useDataProvider,
    useEditContext,
    useNotify,
    useQueryWithStore,
    useRedirect,
    Record,
    usePermissions
} from 'react-admin';
import {ExtendedDataProvider, SelectItem} from "../../types";
import HTTPRequestForm from "../Common/HTTPRequestForm";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const { Title, Paragraph, Text }= Typography;
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;
const { confirm } = Modal;


const DeleteButton = ({ record, style = {} }: { record: Record, style: any },) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const handleClick = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete webhook?',
      onOk() {
        return dataProvider.delete('webhooks', { id: record.id })
          .then(response => {
            notify('Webhook deleted')
            redirect('/webhooks')
          }).catch(error => {
            notify("Something went wrong", "warning")
          })
      },
    });
  };
  return <Button style={style} danger onClick={handleClick}>Delete</Button>;
};

const CreateWebhookForm = (props: any) => {
    const { permissions } = usePermissions();
    const [form] = Form.useForm();
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider: ExtendedDataProvider = useDataProvider();
    const { record, loading, loaded} = useEditContext();
    const [services, setServices] = useState<SelectItem[] | null>(null);
    const { loaded: servicesLoaded, error: servicesError, data: serviceData } = useQueryWithStore({
        type: 'getList',
        resource: 'services',
        payload: {
            pagination: { page: 1, perPage: 20 },
            sort: { field: 'name', order: 'asc' },
            filter: {}
        }
    });


    useEffect(() => {
        const services: SelectItem[] = []
        if (serviceData) {
            for (const item of serviceData) {
                services.push({ text: item.name, value: item.id });
            }
            setServices(services);
        }
    }, [serviceData]);

    useEffect(() => {
        if (record) {
            const headers_: {}[] = []
            if (record.headers) {
                Object.entries(record.headers).map(([key, value]) => headers_.push(
                  { header_key: key, header_value: value })
                )
            }
            form.setFieldsValue({headers: headers_});
        }
    }, []);


    const onSubmit = (values: any) => {
        if (values.headers){
            values['headers'] = Object.fromEntries(values.headers.map(
              (x: { header_key: string; header_value: string; }
              ) => [x.header_key, x.header_value]))
        }

        if (record){
            if (typeof values.service == "object") {
                values.service = values.service.value
            }
            dataProvider.update('webhooks', { id: record.id, data: values, previousData: { id: record.id } })
              .then(() => {
                  notify('Webhook updated successfully');
                  redirect('/webhooks')
              })
              .catch((error: any) => {
                  if (error.status == 400) {
                      for (const [key, value] of Object.entries<any>(error.body)) {
                          form.setFields([
                              { name: key, errors: value }
                          ])
                      }
                  }
              })
        } else {
            dataProvider.create('webhooks', {data: values})
              .then(() => {
                  notify('Webhook created successfully');
                  redirect('/webhooks')
              })
              .catch((error: any) => {
                  if (error.status == 400) {
                      for (const [key, value] of Object.entries<any>(error.body)) {
                          form.setFields([
                              {name: key, errors: value}
                          ])
                      }
                  }
              })
        }
    }

    return (
        <Form
            form={form}
            name="create_webhook"
            labelCol={{ span: 4 }}
            className="row-col"
            labelAlign="left"
            initialValues={record?
              {...record, service: { value: record.service.id, label: record.service.name }}:
              { remember: true, trust_env: true, verify: true }}
            onFinish={onSubmit}
        >
            <Form.Item
                label="Webhook Name"
                name="name"
                rules={[{ required: true, message: 'Please enter Webhook Name' }]}
            >
                <Input placeholder="Name" disabled={permissions !== "ADMIN"}/>
            </Form.Item>
            <Form.Item label="Description" name="description">
                <TextArea
                  showCount
                  maxLength={200}
                  style={{ height: 120, resize: 'none' }}
                  placeholder="Description"
                  disabled={permissions !== "ADMIN"}
                />
            </Form.Item>

            <Form.Item
                label="Service"
                name="service"
                rules={[{ required: true, message: 'Please select a service' }]}
                // initialValue={record && record.service.id}
            >
                <Select
                    placeholder="Select Service"
                    style={{ width: '100%' }}
                    disabled={permissions !== "ADMIN"}
                >
                    {services && services.map(service => <Option key={service.value} value={service.value}>{service.text}</Option>)}
                </Select>
            </Form.Item>

            <HTTPRequestForm form={form} />

            <Form.Item>
                {
                    permissions === "ADMIN" &&
                    <Button style={{ width: "20%" }} type="primary" htmlType="submit" className="form-button">{record? "Update":"Create"}</Button>
                }
                {
                    record &&
                    permissions === "ADMIN" &&
                    <DeleteButton style={{width: "20%", float: "right"}} record={record}/>
                }
            </Form.Item>
        </Form>
    )
}

const WebhookCreate = (props: any) => {
    return (
      <>
          <Card
            className=" header-solid h-full ant-card pt-0"
            bordered={false}
          >
            <Title level={4}>Webhook</Title>
            <Collapse defaultActiveKey={[]} >
              <Panel header="How it works?" key="1" style={{ marginBottom: '20px' }}>
                <Typography>
                  <Paragraph>
                    Webhooks are triggered automatically whenever a consumer's submission generates
                    any change instances for the service selected in the webhook. It is also possible to
                    trigger webhooks manually from the Webhooks List.
                  </Paragraph>
                  <Paragraph>
                    <ul>
                      <li>
                        The webhook is sent to the target url defined below
                      </li>
                      <li>
                        Authentication can be added using the "Authorization" header field.
                        Some examples for this field are as follows:<br />
                        <Text code>Api-Key "api key"</Text>
                        <Text code>Token "token"</Text>
                        <Text code>Bearer/JWT "token"</Text>
                      </li>
                      <li>
                        The body of the webhook request is a json that contains the
                        service's id assigned in NetOrca:
                        <ReactJson
                          style={{ padding: '20px' }}
                          src={{service: 1}}
                          collapsed={false}
                          enableClipboard={false}
                          displayArrayKey={false}
                          displayDataTypes={false}
                          name="body"
                          displayObjectSize={false}
                        />
                      </li>
                      <li>
                        The responses are stored in "responses" tab in Webhooks' list page. Each response
                        contains the status code and the body returned from target endpoint
                      </li>
                      <li>
                        By default, only the last 5 responses are stored in our database
                      </li>
                      <li>
                        Manual triggers are limited to 3 times per minute.
                      </li>
                    </ul>
                  </Paragraph>
                </Typography>
              </Panel>
            </Collapse>
              {props.id &&
                <Edit {...props}>
                    <CreateWebhookForm />
                </Edit>
              }
              {!props.id && <CreateWebhookForm />}
          </Card>
      </>
    )
}

export default WebhookCreate;