import { Card, Col, List, Row, Typography } from 'antd';
import React from 'react';
import {Record, useListContext} from 'react-admin';
import { Link } from 'react-router-dom';
import { getFilterTextForChangeStatus, getTagForChangeStatus, getTagForRuntimeStates } from '../Common/Tags';
import FaIcon from '../Icons/FaIcon';
import { faThLarge, faUsers } from '@fortawesome/free-solid-svg-icons';

const { Title } = Typography;

const GridView = () => {
    const { ids, data, loading, displayedFilters, filterValues, setFilters, page, total, perPage, setPage, setPerPage } = useListContext();

    const tableData: Record[] = []
    ids.forEach(id => tableData.push(data[id]));
    return (

        <List
            data-cy="service-item-result-list"
            pagination={{
                pageSizeOptions: [25, 50, 100],
                onShowSizeChange: (_current, size) => setPerPage(size), showSizeChanger: true,
                total: total, pageSize: perPage, current: page, onChange: page => setPage(page)
            }}
            grid={{ gutter: 24, column: 3, xs: 1, sm: 2, xxl: 4 }}
            dataSource={tableData}
            loading={loading}
            style={{ margin: '20px' }}
            renderItem={(item: any) => (
                <List.Item>
                    <Link to={`/service_items/${item.id}/`}>
                        <Card hoverable bodyStyle={{ padding: '20px' }}
                            title={
                                <>
                                    <Title style={{ width: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }} title={item.name} level={4}>{item.name}</Title>
                                    <span className='text-sm'>{item.service.name}</span>
                                </>
                            }>
                            <span className='service-item-card-text'>{getTagForRuntimeStates(item.runtime_state)}</span>
                            <Link to={`/change_instances?filter={"application_id":"${item.application.id}","service_item_id":"${item.id}","state":"${getFilterTextForChangeStatus(item.change_state)}"}`} >
                                <span className='service-item-card-text link'>{getTagForChangeStatus(item.change_state)}</span>
                            </Link>
                            <Row gutter={6}>
                                <Col>
                                    <Row gutter={6}>
                                        <Col className='text-sm'>{FaIcon(faThLarge)}</Col>
                                        <Col> <span className='service-item-card-text text-sm'> {item.application.name}</span></Col>
                                    </Row>
                                    <Row gutter={6}>
                                        <Col className='text-sm'>{FaIcon(faUsers)}</Col>
                                        <Col> <span className='service-item-card-text text-sm'> {item.service_owner_team.name}</span></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Link>
                </List.Item>
            )}
        />
    )
}


export default GridView;
