import React, {useEffect, useState} from 'react';
import {useDataProvider, useNotify, useRefresh} from "react-admin";
import { Card, Space, Button, Modal, Form, Input, Menu, Dropdown, Typography } from 'antd';
import ReactJson from "react-json-view";
import { DownOutlined, PlusCircleOutlined } from "@ant-design/icons";
import Editor from '@monaco-editor/react';
import {ExtendedDataProvider, VersionizedObjectsTabProps} from "../../types";

const { Title } = Typography;

const VersionizedObjectsTab = ({ resource, light=false, name, attrName, isCreate, record }: VersionizedObjectsTabProps) => {
    const [objs, setObjs] = useState<any>(0);
    const [menu, setMenu] = useState<any>(<Menu items={[]}/>);
    const [version, setVersion] = useState<any>(0);
    const [config, setConfig] = useState<any>({});

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateChangeForm] = Form.useForm();
    const dataProvider: ExtendedDataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const fetchObjects = (() => {
        dataProvider.getList(resource,{
            pagination: { page: 1, perPage: 20 },
            sort: { field: 'version', order: 'asc' },
            filter: { service_item_id: record.id, light: light }
        })
          .then(({ data }) => { setObjs(data) })
          .catch(error => {console.log(error)})
    })
    useEffect(() => { fetchObjects() }, []);

    const handleVersionChange = (e:any) => {
      const sc = objs.filter((sc: any) => sc.version == e.key)[0]
      setVersion(sc.version)
      setConfig(sc[attrName])
    }

    useEffect(() => {
      if (objs != 0) {
          setVersion(objs.length != 0?objs[0].version:0)
          setConfig(objs.length != 0?objs[0][attrName]:{})
          setMenu(
            <Menu
              onClick={handleVersionChange}
              items={objs.map((sData: any) => { return ({ key: sData.version, label: sData.version }) })}
            />
          );
      }
    }, [objs]);

    function onSubmitCreate(values: any) {
        const data: any = { service_item: values.service_item }

        const config = values?.config
        if(config) {
            if(!(config==="" || config=={}))
                data['data'] = JSON.parse(config)
        }
        if(!('data' in data)) {
            notify("Empty dictionary is not accepted", 'warning')
            return
        }


      dataProvider.create(resource, { data: data })
        .then(response => {
            const message = 'New ' + name + ' is created'
            notify(message)
            setIsModalOpen(false);
            fetchObjects();
            refresh();
        })
        .catch(error => notify("Something went wrong", "warning"))
    }

    return (
      <Card style={{ padding: '20px' }}>
        <Space>Version:
          <Dropdown overlay={menu} trigger={['click']}>
            <a onClick={(e) => e.preventDefault()}>
              <Button>
                <Space>
                  <Title level={5}>{version}</Title><DownOutlined />
                </Space>
              </Button>
            </a>
          </Dropdown>
        </Space>
        <ReactJson
          style={{ padding: '20px' }}
          src={config}
          displayDataTypes={false}
          name="data"
          displayArrayKey={false}
          displayObjectSize={false}
        />
        {isCreate &&
            <>
                <Button
                    onClick={() => {setIsModalOpen(true)}}
                    style={{ margin: '10px' }}
                    type="primary"
                    icon={ <PlusCircleOutlined />}
                >
                    {"Create a new " + name}
                </Button>
                <Modal
                    title={"New " + name}
                    visible={isModalOpen}
                    footer={[
                        <Button key="back" onClick={() => setIsModalOpen(false)}>Cancel</Button>,
                        <Button key="submit" type="primary" onClick={updateChangeForm.submit}>Submit</Button>
                    ]}
                    closable={false}
                >
                <Form form={updateChangeForm} name="new_data" className="row-col" onFinish={onSubmitCreate}>
                    <Form.Item  id='config' name="config">
                        <Editor height="50vh" defaultLanguage="json" defaultValue="{}" />
                    </Form.Item>
                    <Form.Item hidden id="service_item" name="service_item" initialValue={record.url}><Input /></Form.Item>
                </Form>
                </Modal>
            </>
        }
      </Card>
    )
}

export default VersionizedObjectsTab;
