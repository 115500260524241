import { Table } from 'antd';
import React from 'react';
import { useListContext } from 'react-admin';
import { Link } from 'react-router-dom';
import { formatDateAndTime } from '../../helpers/utils';
import SkeletonTable, { SkeletonTableColumnsType } from '../Common/SkeletonTable';
import { getFilterTextForChangeStatus, getTagForChangeStatus, getTagForRuntimeStates } from '../Common/Tags';
import { tableOnChangeGenerator } from "../Common/tableUtils";


const ListView = () => {
    const { ids, data, loading, setSort, filterValues, setFilters, page, total, setPerPage, perPage, setPage } = useListContext();

    const columns = [
        {
            title: 'Service Item',
            dataIndex: 'name',
            key: 'name',
            render: (text: any, record: any) =>
            (
                <div title={record.name} style={{ display: 'grid', placeItems: 'stretch' }}>
                    <Link style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} to={`/service_items/${record.id}`}>{record.name}</Link>
                </div>
            ),
            sorter: { key: "name" },
        },
        {
            title: 'State',
            key: 'runtime_state',
            render: (text: any, record: any) => getTagForRuntimeStates(record.runtime_state),
            sorter: { key: "runtime_state" },
        },
        {
            title: 'Change Status',
            key: 'change_state',
            render: (text: any, record: any) => (
                <Link to={`/change_instances?filter={"application_id":"${record.application.id}","service_item_id":"${record.id}","state":"${getFilterTextForChangeStatus(record.change_state)}"}`} >
                    <span className='service-item-card-text link'>{getTagForChangeStatus(record.change_state)}</span>
                </Link>
            )
        },
        {
            title: 'Application',
            dataIndex: ['application', 'name'],
            key: 'application',
        },
        {
            title: 'Service',
            dataIndex: ['service', 'name'],
            key: 'service',
        },
        {
            title: 'Service Owner',
            dataIndex: ['service_owner_team', 'name'],
            key: 'service_owner_team',
        },
        {
            title: 'Last Modified',
            key: 'modified',
            render: (text: any, record: any) => formatDateAndTime(record.modified),
            sorter: { key: "modified" },
        },
    ];

    const tableData: Array<Object> = [];
    ids.forEach(id => {
        tableData.push(data[id])
    });
    return (
            <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
                <Table pagination={{
                    pageSizeOptions: [25, 50, 100],
                    showSizeChanger: true,
                    onShowSizeChange: (_current, size) => setPerPage(size),
                    total: total,
                    pageSize: perPage,
                    current: page,
                }}
                    rowKey="id"
                    columns={columns as SkeletonTableColumnsType[]}
                    dataSource={tableData}
                    onChange={tableOnChangeGenerator(setSort, page, setPage)}
                />
            </SkeletonTable>
    )
}

export default ListView;
