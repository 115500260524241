import {ListBase, ListProps, Record, useDataProvider, useListContext, useNotify, usePermissions} from "react-admin";
import {Button, Card, Table, Tabs} from "antd";
import * as React from "react";
const { TabPane } = Tabs;
import {ExtendedDataProvider} from "../../types";
import {Link} from "react-router-dom";
import {EditOutlined} from "@ant-design/icons";
import SkeletonTable, {SkeletonTableColumnsType} from "../Common/SkeletonTable";
import ResponseListView from "../Common/HTTPResponseList";
import {HealthCheckResponsesColumns} from "../Common/Columns";

const HealthcheckListView = () => {
  const { ids, data, loading, total, perPage, setPage, page } = useListContext();
  const dataProvider: ExtendedDataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions } = usePermissions();

  const triggerForService = (record: any) => {
    dataProvider.getAction('serviceowner_healthchecks', {verb: "trigger/service/"+record.service.id})
      .then((response: any) => {
        notify('Healthcheck triggered successfully')
      }).catch((error: any) => {
        notify('Error triggering healthcheck', 'error')
      })
  }
  const permissionChangeState = ["ADMIN", "GENERAL"].includes(permissions);

  const columns = [
    {
      title: 'Service',
      dataIndex: ['service', 'name'],
      key: 'service',
    },
    {
      title: 'Target URL',
      dataIndex: 'target_url',
      key: 'target_url',
      render: (text: any, record: any) => <div title={text} style={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</div>
    },
    {
      title: 'Crontab',
      dataIndex: 'schedule_crontab',
      key: 'schedule_crontab',
    },
    {
      render: (text: any, record: any) =>
        <div>
          {
            permissionChangeState &&
              <Button style={{ marginRight: '5px'}} onClick={() => triggerForService(record)}>
                Trigger for Service
              </Button>
          }
          <Button style={{ marginRight: '5px'}}>
            <Link to={`/serviceowner_healthchecks/${record.id}`}><EditOutlined />{permissions === "ADMIN"?"Edit":"Show"}</Link>
          </Button>
        </div>

    },

  ]
  const tableData: Record[] = []
  ids.forEach(id => {
    tableData.push(data[id])
  });

  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      extra={permissions === "ADMIN" && <Link to="/serviceowner_healthchecks/create"><Button type='primary'>Create Healthcheck</Button></Link>}
      title="Healthchecks"
    >
      <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
        <Table
          rowKey='id'
          columns={columns}
          pagination={{ total: total, pageSize: perPage, current: page, onChange: page => setPage(page) }}
          dataSource={tableData}
        />
      </SkeletonTable>
    </Card>
  )
}


const HealthcheckList = (props: ListProps) => (
  <Tabs
    defaultActiveKey="1"
    style={{ display: "flex", justifyContent: "space-between" }}
  >
    <TabPane tab="Healthchecks" key="1" >
      <ListBase {...props}>
        <HealthcheckListView />
      </ListBase>
    </TabPane>
    <TabPane tab="Responses" key="2">
      <ResponseListView
        columns={HealthCheckResponsesColumns}
        resource_name={"serviceowner_healthcheck_responses"}
        filters={{}}
      />
    </TabPane>
  </Tabs>
)

export default HealthcheckList;
