import {formatDateAndTime} from "../../helpers/utils";
import {getTagForChangeTypes, getTagForStates} from "./Tags";

export const HealthCheckResponsesColumns = [
    {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Service',
        dataIndex: ['service', 'name'],
        key: 'service',
    },
    {
        title: 'Service Item',
        dataIndex: ['service_item', 'name'],
        key: 'service_item',
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
    },
    {
        title: 'Status Code',
        dataIndex: 'status_code',
        key: 'status_code',
    },
    {
        title: 'created',
        key: 'created',
        render: (text: any, record: any) => formatDateAndTime(record.created)
    },
];


export const ChangeInstanceMiniColumns = [
    {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Service Item',
        dataIndex: ['service_item', 'name'],
        key: 'service_item',
    },
    {
        title: 'Application',
        dataIndex: ['application', 'name'],
        key: 'application',
    },
    {
        title: 'Change Type',
        dataIndex: 'change_type',
        key: 'change_type',
        render: (text: any, record: any) => getTagForChangeTypes(record.change_type),
    },
    {
        title: 'state',
        dataIndex: 'state',
        key: 'state',
        render: (text: any, record: any) => getTagForStates(record.state),
    },
]
