import { Typography } from 'antd';
import React from 'react'
import { useQuery } from 'react-admin';
const { Title, Paragraph } = Typography;

const Licenses = () => {
    const { data, loaded, error } = useQuery({
        type: 'get',
        resource: 'license',
        payload: {},
    });
    const reactLicenses = require('./licences.json');

    if (!loaded) {
        return null
    }
    return (
        <div>
            {Object.entries(data).map(([key, value]) =>
            (
                <>
                    <Title level={5}>{key}</Title>
                    <Typography>{value as { [key: string]: string }}</Typography>
                </>
            ))}
            {
                reactLicenses.map((i: any) => (
                    <>
                        <Title level={5}>{i.name}</Title>
                        <Typography>{i.licenseType}, {i.author==="n/a"?"":i.author}</Typography>
                    </>
                ))
            }
        </div>
    )
}

export default Licenses