import {Card, Table, Tooltip, Typography} from 'antd';
import React, { useEffect, useState } from 'react';
import {ListBase, ListProps, Record as RaRecord, useListContext} from 'react-admin';
import {formatDateAndTime, formatDateAndTimeFromNow} from '../../helpers/utils';
import SkeletonTable, { SkeletonTableColumnsType } from '../Common/SkeletonTable';
import ChangeInstanceRow from './ChangeInstanceRow';
import {getTagForChangeTypes, getTagForStates} from "../Common/Tags";
import GeneralFilter from "../ChangeInstances/GeneralFilter";
import {onExpandOnlyOneRow, tableOnChangeGenerator} from "../Common/tableUtils";
import {Link} from "react-router-dom";
import LazyExpandedRow from "../Common/LazyExpandedRow";

const { Text, Title } = Typography;


const ListView = () => {
  const { ids, data, loading, refetch, setSort, filterValues, setFilters, total, setPage, page, perPage, setPerPage } = useListContext();
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])
  const [columns, setColumns] = useState<any[]>([])  

  useEffect(() => {
    const columnsData = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: {key: "id"},
        render: (text: any, record: any) => (
            <div style={{ display: 'grid', placeItems: 'stretch' }}>
              <Title level={5}>#{record.id}</Title>
              <Text type="secondary"><Tooltip title={formatDateAndTime(record.created)}><Text className='text-sm'>Created {formatDateAndTimeFromNow(record.created)}</Text></Tooltip></Text>
            </div>
        )
      },
      {
        title: 'Service Item',
        dataIndex: ['service_item', 'name'],
        key: 'service_item',
        
        render: (text: any, record: any) => 
        (
          <div title={record.service_item.name} style={{ display: 'grid', placeItems: 'stretch' }}>
            <Link style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} to={`/owner_service_items/${record.service_item.id}`}>{record.service_item.name}</Link>
         </div>
        )
      },
      {
        title: 'Change Type',
        dataIndex: 'change_type',
        key: 'change_type',
        render: (text: any, record: any) => { return getTagForChangeTypes(record.change_type)},
        sorter: {key: "change_type"},
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: (text: any, record: any) => getTagForStates(record.state),
        sorter: {key: "state"},
      },
      {
        title: 'Application',
        dataIndex: ['application', 'name'],
        key: 'application',
      },
      {
        title: 'Service',
        dataIndex: ['service', 'name'],
        key: 'service',
      },
      {
        title: 'Consumer Team',
        dataIndex: ['consumer_team', 'name'],
        key: 'owner',
      },
      {
        title: 'Modified',
        key: 'modified',
        render: (text: any, record: any) => <Tooltip title={formatDateAndTime(record.modified)}><Text type='secondary'>{formatDateAndTimeFromNow(record.modified)}</Text></Tooltip>,
        sorter: true,
        responsive: ['xxl'],
      },
    ]
    setColumns(columnsData);
  }, [])

  const tableData: RaRecord[] = []
  data && ids.forEach(id => {
    tableData.push(data[id])
  });

  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      title="Change Instances"
      extra={
        <GeneralFilter
            filterValues={filterValues}
            setFilterValues={setFilters}
            applicationsResource={"owner_applications"}
            serviceItemsResource={"owner_service_items"}
            teamsResource={"consumer_teams"}
            servicesResource={"services"}
            style={{ width: '350px' }}
        />
      }
    >
      <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
        <Table
          rowKey='id'
          columns={columns}
          dataSource={tableData}
          expandedRowKeys={expandedRowKeys}
          onChange={tableOnChangeGenerator(setSort, page, setPage)}
          pagination={{
            pageSizeOptions: [25, 50, 100],
            onShowSizeChange: (_current, size) => setPerPage(size), showSizeChanger: true,
            total: total, pageSize: perPage, current: page,
          }}
          expandable={{
            onExpand: onExpandOnlyOneRow(setExpandedRowKeys),
            expandedRowRender: (record) => <LazyExpandedRow lightRecord={record} resource={"owner_change_instances"} refresh={refetch} ExpandedRowComponent={ChangeInstanceRow}/>,
            expandRowByClick: true
          }}
        />
      </SkeletonTable>
    </Card>
  )
}

const ChangeList = (props: ListProps) => (
  <ListBase
    sort={{ field: 'id', order: 'DESC' }}
    filter={{light: true}}
    perPage={25}
    filterDefaultValues={{ state: 'PENDING,APPROVED' }}
    {...props}
  >
    <ListView />
  </ListBase>
)

export default ChangeList;
