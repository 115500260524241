import { faRocket } from "@fortawesome/free-solid-svg-icons";
import { Card, Col, Row, Typography } from "antd";
import React from 'react';
import FaIcon from "../Icons/FaIcon";
const { Title, Text } = Typography;

const ServiceItemsWidget = ({ data }: { data: any }) => {
    return (
        <Card bordered={false} className="criclebox ">
            <Row>
                <Title style={{ marginBottom: '20px' }} level={5}>Service Items</Title>
                <div className="icon-box">{FaIcon(faRocket)}</div>
            </Row>
            <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                    <Col span={24}>
                        <span>In Service</span>
                        <Title level={3}>
                            {data.sis_in_service}
                        </Title>
                    </Col>

                </Row>
            </div>
        </Card>
    )
}

export default ServiceItemsWidget;