import ReactDiffViewer from "react-diff-viewer";
import React from "react";

const MetadataDiffView = ({ submission }: { submission: any }) => {
    const metadataOldJson = JSON.stringify (submission?.previous_metadata || {}, null, 4)
    const metadataNewJson = JSON.stringify (submission?.metadata, null, 4)
    const splitView = metadataNewJson !== metadataOldJson

    return <ReactDiffViewer
        leftTitle={splitView?"Old Declaration":"No change detected"}
        rightTitle={'New Declaration'}
        oldValue={metadataOldJson}
        newValue={metadataNewJson}
        splitView={splitView}
    />
}

export default MetadataDiffView;
