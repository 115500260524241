import ServiceItemsList from './ServiceItemsList';
import ServiceItemDetailCard from "../ServiceItems/DetailView";
import {EditBase} from "react-admin";
import React from "react";

const ServiceItemDetail = (props: any) => {
    return (
        <EditBase {...props} >
            <ServiceItemDetailCard
                pov={"serviceowner"}
                serviceItemsResource={"owner_service_items"}
                changeInstancesResource={"owner_change_instances"}
                deployedItemsResource={"serviceowner_deployed_items"}
                healthcheckResource={"serviceowner_healthchecks"}
                healthcheckResponsesResource={"serviceowner_healthcheck_responses"}
            />
        </EditBase>
    )
}

export default {
    list: ServiceItemsList,
    edit: ServiceItemDetail
}
