export const SET_VIEW_MODE = 'SET_VIEW_MODE';
export const CONSUMER_VIEW_MODE = 'CONSUMER';
export const SET_WELCOME_SCREEN_SHOWN = 'SET_WELCOME_SCREEN_SHOWN';

export const setViewMode = (
    mode: string
) => ({
    type: SET_VIEW_MODE,
    payload: mode,
});

export const setWelcomeScreenShown = (
    shown: boolean
) => ({
    type: SET_WELCOME_SCREEN_SHOWN,
    payload: shown,
});