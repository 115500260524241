import { Typography } from "antd";
import React from "react";
import ReactJson from "react-json-view";

const { Title } = Typography;

const Metadata = ({ record }: { record: any }) => {

    return (
        <>
            <Title level={5}>Application Metadata</Title>
            <ReactJson
                style={{ padding: '20px' }}
                src={record.application.metadata}
                displayArrayKey={false}
                displayDataTypes={false}
                collapsed
                name={false}
                displayObjectSize={false}
            />

            <Title level={5}>Team Metadata</Title>
            <ReactJson
                style={{ padding: '20px' }}
                src={record.consumer_team.metadata}
                displayArrayKey={false}
                collapsed
                displayDataTypes={false}
                name={false}
                displayObjectSize={false}
            />
        </>
    )
}

export default Metadata;