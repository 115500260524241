import {Tag, Typography} from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  StopOutlined,
  IssuesCloseOutlined,
  CheckOutlined,
  HeartOutlined
} from "@ant-design/icons";
import React from "react";

const { Text } = Typography;


export const getTagForRuntimeStates = (status: string) => {
  switch (status) {
    case 'IN_SERVICE':
      return <Tag icon={<CheckCircleOutlined />} color="success">In Service</Tag>
    case 'REQUESTED':
      return <Tag icon={<SyncOutlined />} color="processing">Requested</Tag>
    case 'DECOMMISSIONED':
      return <Tag icon={<CloseCircleOutlined />} color="pink">Decommissioned</Tag>
    default:
      return <Tag icon={<ClockCircleOutlined />} color="default">{status}</Tag>
  }
}


export const getTagForChangeStatus = (status: string) => {
  switch (status) {
    case 'ALL_CHANGES_COMPLETED':
      return <Tag style={{marginRight:0}} icon={<CheckCircleOutlined />} color="#95de64">All Changes Completed</Tag>
    case 'CHANGES_PENDING':
      return <Tag style={{marginRight:0}} icon={<SyncOutlined />} color="default">Changes Pending</Tag>
    case 'CHANGES_REJECTED':
      return <Tag style={{marginRight:0}} icon={<StopOutlined />} color="orange">Changes Rejected</Tag>
    case 'CHANGES_ERRORED':
      return <Tag style={{marginRight:0}} icon={<CloseCircleOutlined />} color="#ff7875">Error in Changes</Tag>
    case 'CHANGES_APPROVED':
      return <Tag style={{marginRight:0}} icon={<ClockCircleOutlined />} color="#69c0ff">Changes Approved</Tag>
    default:
      return <Tag style={{marginRight:0}} icon={<ClockCircleOutlined />} color="default">{status}</Tag>
  }
}


export const getFilterTextForChangeStatus = (status: string) => {
  switch (status) {
    case 'ALL_CHANGES_COMPLETED':
      return 'COMPLETED';
    case 'CHANGES_PENDING':
      return 'PENDING,APPROVED';
    case 'CHANGES_ERRORED':
      return 'ERROR';
    default:
      return '';
  }
}


export const getTagForChangeTypes = (status: string) => {
  switch (status) {
    case 'CREATE':
      return <Tag icon={<CheckCircleOutlined />} color="success">CREATE</Tag>
    case 'MODIFY':
      return <Tag icon={<SyncOutlined />} color="processing">MODIFY</Tag>
    case 'DELETE':
      return <Tag icon={<CloseCircleOutlined />} color="error">DELETE</Tag>
    default:
      return <Tag icon={<ClockCircleOutlined />} color="default">{status}</Tag>
  }
}


export const getTagForStates = (status: string) => {
  switch (status) {
    case 'PENDING':
      return <Tag icon={<ClockCircleOutlined />} color="default">PENDING</Tag>
    case 'REJECTED':
      return <Tag icon={<StopOutlined />} color="orange">REJECTED</Tag>
    case 'APPROVED':
      return <Tag icon={<CheckCircleOutlined />} color="#69c0ff">APPROVED</Tag>
    case 'ERROR':
      return <Tag icon={<CloseCircleOutlined />} color="#ff7875">ERROR</Tag>
    case 'CLOSED':
      return <Tag icon={<IssuesCloseOutlined />} color="default">CLOSED</Tag>
    case 'COMPLETED':
      return <Tag icon={<CheckOutlined />} color="#95de64">COMPLETED</Tag>
    default:
      return <Tag icon={<ClockCircleOutlined />} color="default">{status}</Tag>
  }
}


export const getTagForResponsesStatus = (status_code: number) => {
  if (!status_code)
    return <Tag>No response</Tag>
  if (status_code >= 200 && status_code < 300)
    return <Tag  icon={<HeartOutlined />}  color="#108ee9">{status_code}</Tag>
  else if (status_code >= 400)
    return <Tag  icon={<HeartOutlined />}  color="#f50">{status_code}</Tag>

  return {status_code}
}

export const PriceTag = ({ amount, currency="GBP" }: { amount: number, currency: string }) => {
    const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: currency,});
    return <>{formatter.format(amount)}</>
}

export const renderNameAndTagWithEllipsis = (name: string, tags: [string], maxWidth: number) => {
  let total_length = name.length;
  const tagsRender: JSX.Element[] = []
  for(const tag in tags){
    tagsRender.push(
        <Tag><Text style={{ fontSize: '12px' }} type="secondary">#{tags[tag]}</Text></Tag>
    )
    total_length += tags[tag].length
    if (total_length >= maxWidth){
      tagsRender.push(<>...</>)
      break
    }
  }
  return (
      <>
        {name}
        <br/>
        {tagsRender.map((tagRender: any) => tagRender)}
      </>
  )
}
