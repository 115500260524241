import {Button, Card, Col, Row, Spin, Tabs, Typography} from 'antd';
import React, { useEffect, useState } from 'react';
import {ListBase, ListProps, Record, useGetIdentity, useListContext, useDataProvider, useNotify} from 'react-admin';
import ReactJson from 'react-json-view';
import { Link } from 'react-router-dom';
import useGetGeneralSettings from '../../providers/useGetGeneralSettings';
import ApiKeyList from './ApiKeyList';
import TeamMappings from './SubComponents/TeamMappings';
import TeamEdit from "./TeamEdit";
import TeamMembershipList from "./TeamMembershipList";
import ActivateMembership from "./SubComponents/ActivateMembership";

const { TabPane } = Tabs;
const { Text } = Typography;


const ListView = () => {
  const { identity } = useGetIdentity();
  const dataProvider = useDataProvider()
  const notify = useNotify();
  const generalSettings = useGetGeneralSettings();
  const [isPendingRequest, setIsPendingRequest] = useState(false)
  const [userData, setUserData] = useState<any>()
  const { ids, data, loaded } = useListContext();
  const ldap = generalSettings.find(setting => setting.name=='LDAP')

  useEffect(() => {
    identity && dataProvider.getOne('users', {id: identity.id})
      .then((response: any) => {
        setUserData(response.data)
      })
      .catch((error: any) => {
        console.error(error)
      })
  }, [identity])

  const tableData: Record[] = [];
  ids.forEach(id => {
    tableData.push(data[id])
  });

  useEffect(() => {
    if (loaded && !tableData[0]) {
      dataProvider.getList('team_join_requests', {
        pagination: { page: 1, perPage: 20 },
        sort: { field: "name", order: "asc" },
        filter: {}
      })
        .then((response: any) => {
          if(response.data[0]) {
            setIsPendingRequest(!response.data[0].is_accepted)
          }
        }).catch((error: any) => {
          console.error(error)
          notify('Error checking for pending join requests', 'error')
        })
    }
  }, [loaded])

  if (!loaded || !userData) {
    return <Spin style={{ 'width': '100%' }} />;
  }

  return (
    userData?.active_membership ?
      <>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Members" key="1">
            <TeamMembershipList/>
          </TabPane>
          <TabPane tab="Metadata" key="2">
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Team Metadata">
              <ReactJson style={{ padding: '20px' }} src={userData && userData?.active_team?.metadata} displayArrayKey={false} collapsed={false} displayDataTypes={false} name="team_metadata" displayObjectSize={false} />
            </Card>
          </TabPane>
          {userData?.active_membership?.role == 'ADMIN' && <TabPane tab="API Keys" key="3"><ApiKeyList /></TabPane>}
          {userData?.active_membership?.role == 'ADMIN' && ldap?.ENABLED && <TabPane tab="Team Mappings" key="4"><TeamMappings team={userData?.active_team}/></TabPane>}
          {userData?.active_membership?.role == 'ADMIN' && <TabPane tab="Team Settings" key="5"><TeamEdit team={userData?.active_team}/></TabPane>}
        </Tabs>
      </> :

      <>
        <Card
          bordered={false}
          className="criclebox  mb-24"
          title="Team Management"
        >
          {isPendingRequest && <Text>Join Request is pending approval.</Text>}
          {!isPendingRequest && <><Text>You're currently not part of any teams. Click below to create a team</Text>
            <Row style={{ 'width': '20%', marginTop: '20px' }} justify="space-between">
              <Col span={12}><Button type='primary'><Link to="/teams/create">Create Team</Link></Button></Col>
            </Row></>}
        </Card>
      </>
  )
}

const TeamList = (props: ListProps) => {
  return (
    <>
      <ActivateMembership/>
      <ListBase {...props}>
        <ListView />
      </ListBase>
    </>
  )
}

export default TeamList;
