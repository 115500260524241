import { Drawer, Layout } from "antd";
import React, { useEffect } from 'react';
import { setSidebarVisibility, ReduxState } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import SideNav from './SideNav';

const Sidebar = () => {
    const { Header: AntHeader, Content, Sider } = Layout;
    const sidebarOpen = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
    const dispatch = useDispatch()

    return (
        <>
            <Drawer
                title={false}
                placement="left"
                closable={false}
                onClose={() => dispatch(setSidebarVisibility(false))}
                visible={sidebarOpen}
                key="left"
                width={250}
                className='drawer-sidebar'>
                <Layout className={`layout-dashboard`}>
                    <Sider width={250}
                        theme="light"
                        className={`sider-primary ant-layout-sider-primary`}
                        style={{ background: 'transparent' }}>
                        <SideNav />
                    </Sider>
                </Layout>
            </Drawer>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                trigger={null}
                width={250}
                theme="light"
                className={`sider-primary ant-layout-sider-primary`}
                style={{ background: 'transparent' }}
            >
                <SideNav />
            </Sider>
        </>
    );
};

export default Sidebar;