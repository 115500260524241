import {Button, Form, Input, Select, Typography} from "antd";
import * as React from "react";
import { CreateBase, useCreateContext, useDataProvider, useGetIdentity, useNotify, useRedirect } from 'react-admin';
import {useEffect} from "react";
const { Title } = Typography

const JoinTeamForm = () => {
    const [form] = Form.useForm();
    const notify = useNotify();
    const redirect = useRedirect();
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();
    const [options, setOptions] = React.useState<any>(null);

    const onSubmit = (values: any) => {
        //@ts-ignore
        dataProvider.create('user_join_requests', { data: { team: values.team, email: identity?.email } })
            .then(data => {
                notify('Team join request successful');
                redirect('/teams')
            })
            .catch((error: any) => {
                if (error.status == 400) {
                    notify('Error submitting request', 'error')
                    for (const [key, value] of Object.entries<any>(error.body)) {
                        form.setFields([
                            { name: key, errors: value }
                        ])
                    }
                }
            })
    }

    const fetchTeamList = () => {
        return dataProvider.getList('all_teams', {
            pagination: { page: 1, perPage: 20 },
            sort: { field: "name", order: "asc" },
            filter: {}
        }).then((data: any) => {
            setOptions(data.data.map((team: any) => ({label: team.name, value: team.id})))
        })
    }

    useEffect(() => {
        if(!options)
            fetchTeamList()
    }, []);

    return (
      identity?
        <Form
            form={form}
            name="join_team"
            className="row-col"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
        >
            <Form.Item
                name="team"
                rules={[{ required: true, message: 'Please input Team Name' }]}
            >
                <Select
                  showSearch
                  options={options}
                  optionFilterProp="children"
                  filterOption={(input: string, option: any) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
            </Form.Item>
            <Form.Item>
                <Button style={{ width: "100%" }} type="primary" htmlType="submit" className="form-button">
                    Request to Join
                </Button>
            </Form.Item>
        </Form>
        :
        <></>
    );
}

const JoinTeam = (props: any) => (
    <>
        <Title level={5} style={{ textAlign: 'center' }} className="mb-15">Join Existing Team</Title>

        <JoinTeamForm />
    </>
)

export default JoinTeam;