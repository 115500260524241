import React from "react";
import { EditBase, useEditContext } from "react-admin";
import { Card, Col, Row, Space, Tabs, Tag, Tooltip, Typography } from "antd";
import { getTagForChangeStatus } from "../Common/Tags";
import ReactJson from "react-json-view";
import { formatDateAndTime, formatDateAndTimeFromNow } from "../../helpers/utils";
import MetadataDiffView from "./SubComponents/MetadataDiffView";
import ChangeInstanceExpandedTable from "./SubComponents/ChangeInstanceExpandedTable";

const { Title, Text } = Typography
const { TabPane } = Tabs

const SubmissionsDetailView = () => {
    const { record } = useEditContext<any>();
    return (
        <>
            {
                record ?
                    <Card style={{ padding: '24px' }} className=" header-solid h-full ant-card pt-0">
                        <Row>
                            <Col style={{ display: 'flex', justifyContent: 'flex-end' }} span={24}>
                                <Space align="center">
                                    {record.created && <Tooltip title={formatDateAndTime(record.created)}><Text className='text-sm'>Created {formatDateAndTimeFromNow(record.created)}</Text></Tooltip>}
                                </Space>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Title level={3}>Submission {record.id}</Title>
                            </Col>

                            <Col style={{ display: 'flex', justifyContent: 'flex-end' }} span={16}>
                                <Space>
                                    {record.commit_id && <Tag style={{ marginRight: 0 }} color="default">commit id: {record.commit_id}</Tag>}
                                    {getTagForChangeStatus(record.change_status)}
                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ paddingTop: '10px' }}>
                            <Card style={{ flexGrow: 1, width: '100%' }}>
                                <Tabs defaultActiveKey="1" >
                                    <TabPane tab="Change Instances" key="1">
                                        <ChangeInstanceExpandedTable resource={"change_instances"} filterParam={{ submission_id: record.id, light: true }}/>
                                    </TabPane>
                                    <TabPane tab="Declaration" key="2">
                                        <ReactJson
                                            style={{ padding: '20px' }}
                                            src={record.request_json}
                                            displayDataTypes={false}
                                            name={null}
                                            displayArrayKey={false}
                                            displayObjectSize={false}
                                        />
                                    </TabPane>
                                    <TabPane tab="Metadata" key="3">
                                        <MetadataDiffView submission={record}/>
                                    </TabPane>
                                </Tabs>
                            </Card>
                        </Row>
                    </Card>
                    :
                    <></>
            }
        </>
    )
}

const SubmissionsDetail = (props: any) => {
    return (
        <EditBase {...props} >
            <SubmissionsDetailView />
        </EditBase>
    )
}

export default SubmissionsDetail;
