import { ExtendedDataProvider } from "../../../types";
import { useDataProvider, useNotify, useQuery, useQueryWithStore } from "react-admin";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { Badge, Button, Dropdown, Menu, Row, Space, Spin, Tooltip, Typography } from "antd";
import { DownOutlined, ExclamationCircleFilled } from "@ant-design/icons";
const { Title } = Typography;


const Declarations = (props: any) => {
    const [objs, setObjs] = useState<any>(0);
    const [menu, setMenu] = useState<any>(<Menu items={[]} />);
    const [version, setVersion] = useState<any>(0);
    const [schemaVersion, setSchemaVersion] = useState<any>(0);
    const [config, setConfig] = useState<any>({});
    const notify = useNotify();
    const dataProvider: ExtendedDataProvider = useDataProvider();

    const { service_item, resource } = props;
    const attrName = "declaration"

    useEffect(() => {
        dataProvider.getAction(resource, { id: service_item.id, verb: 'declarations' })
            .then((response: any) => {
                setObjs(response.data?.results)
            }).catch((error: any) => {
                notify('Error fetching', 'error')
            })
    }, [])


    const handleVersionChange = (e: any) => {
        const sc = objs.filter((sc: any) => sc.version == e.key)[0]
        setVersion(sc.version)
        setConfig(sc[attrName])
        setSchemaVersion(sc.schema.version)
    }

    useEffect(() => {
        if (objs != 0) {
            setVersion(objs.length != 0 ? objs[0].version : 0)
            setConfig(objs.length != 0 ? objs[0][attrName] : {})
            setSchemaVersion(objs.length != 0 ? objs[0].schema.version : 0)
            setMenu(
                <Menu
                    onClick={handleVersionChange}
                    items={objs.map((sData: any) => { return ({ key: sData.version, label: sData.version }) })}
                />
            );
        }
    }, [objs]);

    return (
        objs ?
            <>
                Declaration Version: <Space />
                <Dropdown overlay={menu} trigger={['click']}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Button size="small"><Space><Title level={5}>{version}</Title><DownOutlined /></Space></Button>
                    </a>
                </Dropdown>
                <Typography.Text style={{ float: "right" }} type="secondary" >
                    {
                        service_item.service.name} Schema Version: {
                        service_item.is_validated_minimum_schema ?
                            <Tooltip title="This declaration has been validated against the minimum version schema" color={"orange"} placement={"topRight"}>
                                {schemaVersion}{" "}
                                <ExclamationCircleFilled style={{ color: 'orange' }} />
                            </Tooltip>
                            :
                            <>{schemaVersion}</>
                    }
                </Typography.Text>
                <ReactJson
                    style={{ padding: '20px' }}
                    src={config}
                    displayDataTypes={false}
                    name="data"
                    displayArrayKey={false}
                    displayObjectSize={false}
                />
            </>
            :
            <Spin style={{ 'width': '100%' }} />
    )
}
export default Declarations;
