import { Form, Input, Modal, Select } from 'antd'
import React, { useState } from 'react'
import { useQuery } from 'react-admin'

export interface HandleModalSubmit {
    (selectedService: string, serviceName: string): void;
}

const AddServiceItemModal = ({ modalVisible, setModalVisible, handleModalSubmit }: { modalVisible: boolean, setModalVisible: Function, handleModalSubmit: HandleModalSubmit }) => {
    const { Option } = Select
    const [selectedService, setSelectedService] = useState<string | null>(null)
    const [serviceItemName, setServiceItemName] = useState<string | null>(null)
    const [serviceItemModalForm] = Form.useForm();

    const handleSubmit = () => {
        setModalVisible(false)
        if (!(selectedService && serviceItemName)) {
            console.error('servicename and service type required')
            return
        }
        handleModalSubmit(selectedService, serviceItemName)
    }

    const handleCancel = () => {
        setModalVisible(false)
    }
    
    const { data, error, loaded } = useQuery({
        type: "getList",
        resource: 'browse_services',
        payload: {
            pagination: { page: 1, perPage: 500 },
            sort: { field: "name", order: "asc" },
            filter: {}
        },
    });

    return (
        <>
            <Modal
                title="Add Service Item"
                visible={modalVisible}
                onOk={()=>serviceItemModalForm.submit()}
                onCancel={handleCancel}
            >
                <Form onFinish={handleSubmit} form={serviceItemModalForm}>
                    <Form.Item
                        name="serviceItemName"
                        rules={[{ required: true, message: 'Please input service item name' }]}
                    >
                        <Input required onChange={(e) => setServiceItemName(e.target.value)} placeholder="Service Item Name" />
                    </Form.Item>
                    <Form.Item
                        name="serviceName"
                        rules={[{ required: true, message: 'Please select service' }]}
                    >
                        <Select
                            placeholder="Select Service"
                            onChange={(value) => setSelectedService(value)}
                            style={{ width: '100%' }}
                        >
                            {data && data.map((service: any) => {
                                return (
                                    <Option key={service.id} value={service.name}>
                                        {service.name}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default AddServiceItemModal