import SkeletonTable, {SkeletonTableColumnsType} from "../Common/SkeletonTable";
import { Card, Table, Tabs } from 'antd';
import React from "react";
import {Record, useListContext, useQueryWithStore} from "react-admin";
import {formatDateAndTime} from "../../helpers/utils";
import ReactJson from "react-json-view";


const ResponseListView = ({ resource_name, columns = [], filters = {} }: { resource_name: string, columns: object[], filters: object }) => {

    const { loaded, error, data, total } = useQueryWithStore({
      type: 'getList',
      resource: resource_name,
      payload: {
        pagination: { page: 1, perPage: 20 },
        sort: { field: 'id', order: 'dsc' },
        filter: filters
      }
    });


  const ExpandedRow = ({ record }: { record: Record }) => {
    return (
      <ReactJson
        src={record.body}
        displayArrayKey={false}
        displayDataTypes={false}
        collapseStringsAfterLength={15}
        name="body"
        displayObjectSize={false}
      />
    )
  }

  return (
    <SkeletonTable
      loading={!loaded}
      columns={columns as SkeletonTableColumnsType[]}
    >
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data}
        expandable={{
          expandedRowRender: (record) => <ExpandedRow record={record} />,
          expandRowByClick: true
        }}
      />
    </SkeletonTable>
  )
}

export default ResponseListView;
