import { Button, Card, Form, Input, Typography } from "antd";
import * as React from "react";
import {Record, useDataProvider, useNotify, usePermissions, useQuery, useRedirect } from 'react-admin';
import DebounceSelect from "../Common/DebounceSelect";
import {useEffect, useState} from "react";

const { Title, Paragraph } = Typography


const AllowedTeamsForm = ({ record }: { record: Record }) => {
  const { permissions } = usePermissions();
  const [form] = Form.useForm();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [selectedAllowedTeams, setSelectedAllowedTeams] = useState<any>([]);
  const { data, loading, error } = useQuery({
    type: 'getOne',
    resource: 'services',
    payload: { id: record.id },
  });

  const onSubmit = (values: any) => {
    let preparedData = []
    if (typeof values.allowed_teams[0] == 'object') {
      preparedData = values.allowed_teams.map((item: any) => item.value)
    } else {
      preparedData = values.allowed_teams
    }

    dataProvider.update('services', {
      id: values.id,
      data: { allowed_teams: preparedData },
      previousData: { id: values.id }
    })
      .then(data => { notify('Save successful') })
      .catch((error: any) => {
          notify('Error submitting request', 'error')
          if (error.status == 400) {
              for (const [key, value] of Object.entries<any>(error.body))
                  form.setFields([{ name: key, errors: value }])
          }
      })
  }

  useEffect(() => {
    if (data && data.allowed_teams) {
      const allowed_teams = data.allowed_teams.map((item: any) => item.id)
      setSelectedAllowedTeams(allowed_teams)
      form.setFieldsValue({
        allowed_teams: allowed_teams
      })
    }
  }, [data, loading])

  const fetchTeamList = async (values: string): Promise<any> => {
    //@ts-ignore
    return dataProvider.getList('all_teams', {
        pagination: { page: 1, perPage: 20 },
        sort: { field: "name", order: "asc" },
        filter: {
          'name': values
        }
      }
    ).then((data: any) => {
      return data.data.map((result: any) => ({
        label: result.name,
        value: result.id
      }))
    })
  }

  return (
    selectedAllowedTeams?
    <Form
      form={form}
      name="allowed_teams"
      className="row-col"
      initialValues={{ remember: true }}
      onFinish={onSubmit}
    >
      <Form.Item
        name="allowed_teams"
        valuePropName="option"
        rules={[{ required: false, message: 'Please select teams' }]}
      >
        <DebounceSelect
          mode="multiple"
          showSearch
          placeholder="Select Teams"
          fetchOptions={fetchTeamList}
          value={selectedAllowedTeams}
          onChangeSelect={newValue => {
            setSelectedAllowedTeams(newValue);
            form.setFieldsValue({allowed_teams: newValue})
          }}
          style={{ width: '100%' }}
          disabled={permissions !== "ADMIN"}
        />
      </Form.Item>
      <Form.Item
        hidden
        id="id"
        name="id"
        initialValue={record.id}
      >
        <Input />
      </Form.Item>
        {
            permissions === "ADMIN" &&
            <Form.Item>
                <Button style={{ width: "100%" }} type="primary" htmlType="submit" className="form-button">Save</Button>
            </Form.Item>
        }
    </Form>
      :
      <>Loading</>
  );

}

const ManageAllowedTeams = ({ record }: { record: any }) => (
  <Card style={{ padding: '20px' }}>
    <Typography>
      <Title
        level={5}
        style={{ textAlign: 'center', paddingBottom: '10px' }}
        className="mb-15"
      >
        Manage Allowed Teams
      </Title>
      <Paragraph>
        If the allowed teams is empty, the service will be public and all teams have access to it.
        However, if any team is added to the list below, <br/>
        the service will be private and can be seen only by the allowed teams. <br/>
        Note: This feature applies to service item creation. The service is still available for existing service items
        even if it becomes private.
      </Paragraph>
    </Typography>
    <AllowedTeamsForm record={record} />
  </Card>
)

export default ManageAllowedTeams;
