import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Switch } from 'antd';
import { useDataProvider, useNotify } from 'react-admin';
import {ExtendedDataProvider} from "../../types";

const FeedbackSettingsForm = () => {
  const notify = useNotify()
  const [currentSettings, setCurrentSettings] = useState<any>({});
  const [loadedSettings, setLoadedSettings] = useState<boolean>(false);
  const [form] = Form.useForm();
  const dataProvider: ExtendedDataProvider = useDataProvider()

  useEffect(() => {
    dataProvider.getOne('general_settings', {id: "Feedback"})
      .then((data: any) => {
        setCurrentSettings(data?.data)
        setLoadedSettings(true)
      }).catch((error: any) => {
        if (error.status == 404) {
          setCurrentSettings({})
          setLoadedSettings(true)
        } else {
          console.log(error)
        }
      })
  }, [])

  if (!loadedSettings) {
    return null;
  }

  const onSubmit = (values: any) => {
    const submission = {
      "name": "Feedback",
      "config": values
    }
    if (currentSettings && Object.keys(currentSettings).length != 0) {
      // update existing
      dataProvider.update('general_settings', {
        id: currentSettings.id,
        data: submission,
        previousData: {id: currentSettings.id}
      })
        .then(response => {
          notify('Settings have been saved.')
        }).catch(error => {
        notify("Something went wrong", "warning")
      })
    } else {
      //create
      dataProvider.create('general_settings', { data: submission })
        .then(response => {
          setCurrentSettings(response?.data)
          notify('Settings have been saved.')
        }).catch(error => {
        console.error(error)
        notify("Something went wrong", "warning")
      })
    }
  }

  return (
    <>
      <Form form={form}
            labelAlign='left'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
              ENABLED: currentSettings?.['config']?.ENABLED
            }}
            onFinish={onSubmit}>

        <Form.Item valuePropName='checked' label="Enable Feedback" name="ENABLED" initialValue={currentSettings?.['config']?.ENABLED || false}>
          <Switch defaultChecked={currentSettings?.['config']?.ENABLED == true} />
        </Form.Item >

        <Form.Item
          label="Feedback Link"
          initialValue={currentSettings?.['config']?.feedback_link}
          name="feedback_link"
          rules={[{ required: true, message: 'Please input feedback link' }]}
        >
          <Input placeholder="e.g. jira.com" />
        </Form.Item>

        <Form.Item >
          <Button type="primary" htmlType="submit" className="form-button">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default FeedbackSettingsForm;
