import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Input, Modal, Row, Select, Switch, Table, Tabs, Typography} from "antd";
import {useDataProvider, useGetIdentity, useNotify, useRefresh} from "react-admin";
import {ExtendedDataProvider} from "../../types";
import {formatDateAndTime} from "../../helpers/utils";
import SkeletonTable, {SkeletonTableColumnsType} from "../Common/SkeletonTable";
import TabPane = Tabs.TabPane;
import {ExclamationCircleOutlined} from "@ant-design/icons";
import useGetGeneralSettings from "../../providers/useGetGeneralSettings";
const { Text } = Typography
const { confirm } = Modal;


const ProfileEdit = () => {
    const { identity } = useGetIdentity();
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider: ExtendedDataProvider = useDataProvider()
    const generalSettings = useGetGeneralSettings();
    const ldap = generalSettings.find(setting => setting.name=='LDAP')

    const [userData, setUserData] = useState<any>(null);
    const [userMemberships, setUserMemberships] = useState<any>(null);
    const [userInvitations, setUserInvitations] = useState<any>(null);
    const [userJoinRequests, setUserJoinRequests] = useState<any>(null);

    // join request stuff
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allTeams, setAllTeams] = React.useState<any>(null);
    const fetchTeamList = () => {
        return dataProvider.getList('all_teams', {
            pagination: { page: 1, perPage: 20 },
            sort: { field: "name", order: "asc" },
            filter: {}
        }).then((data: any) => {
            setAllTeams(data.data.map((team: any) => ({label: team.name, value: team.id})))
        })
    }

    useEffect(() => { if(!allTeams) { fetchTeamList() } }, []);

    const onJoinRequestSubmit = (values: any) => {
        //@ts-ignore
        dataProvider.create('user_join_requests', { data: { team: values.team, email: identity?.email } })
          .then(data => {
              notify('join request sent successful');
              setIsModalOpen(false)
              setUserData(null)
              refresh();
          })
          .catch((error: any) => {
              if (error.status == 400) {
                  notify('Error submitting request', 'error')
                  for (const [key, value] of Object.entries<any>(error.body)) {
                      form.setFields([{ name: key, errors: value }])
                  }
              }
          })
    }
    function deleteJoinRequest(record: any) {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to delete join request?',
            onOk() {
                return dataProvider.delete('user_join_requests', { id: record.id })
                  .then(response => {
                      notify('join request deleted')
                      setUserData(null)
                      refresh();
                  }).catch(error => {
                      notify("Something went wrong", "warning")
                  })
            },
        });
    }
    // join request stuff end

    const fetchData = () => {
        if (identity) {
            dataProvider.getOne('users', {id: identity.id})
              .then((data: any) => {
                  setUserData(data?.data)
              })
              .catch((error: any) => {
                  console.log(error)
              })

            dataProvider.getList('user_memberships', {
                pagination: {page: 1, perPage: 20},
                sort: {field: "id", order: "dsc"},
                filter: {}
            })
              .then((data: any) => {
                  setUserMemberships(data?.data)
              })
              .catch((error: any) => {
                  console.log(error)
              })

            dataProvider.getList('user_invitations', {
                pagination: {page: 1, perPage: 20},
                sort: {field: "id", order: "dsc"},
                filter: {}
            })
              .then((data: any) => {
                  setUserInvitations(data?.data)
              })
              .catch((error: any) => {
                  console.log(error)
              })

            dataProvider.getList('user_join_requests', {
                pagination: {page: 1, perPage: 20},
                sort: {field: "id", order: "dsc"},
                filter: {}
            })
              .then((data: any) => {
                  setUserJoinRequests(data?.data)
              })
              .catch((error: any) => {
                  console.log(error)
              })
        }
    }

    useEffect(() => {
        if (identity && !userData) { fetchData() }
    }, [identity, userData]);

    if (!identity) {return <></>}

    const actionInvitation = (record: any, action: string) => {
        dataProvider.getAction('user_invitations', { id: record.id, verb: action})
          .then((response: any) => {
              setUserData(null)
              refresh();
          }).catch((error: any) => {
            notify('Error action', 'error')
        })
    }

    function deleteMembership(record: any) {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to leave the team',
            onOk() {
                return dataProvider.delete('user_memberships', { id: record.id })
                  .then(response => {
                      notify('Membership deleted')
                      setUserData(null)
                      refresh();
                  }).catch(error => {
                      notify("Something went wrong", "warning")
                  })
            },
        });
    }

    let columns_mem = [
        {
            title: 'Team',
            dataIndex: 'team_name',
            key: 'team_name',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Date joined',
            dataIndex: 'created',
            key: 'created',
            render: (text: any, record: any) => formatDateAndTime(record.created)
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (text: any, record: any) => record.active?"Active":"-"
        },
        {
            render: (text: any, record: any) => <Button style={{ marginRight: '5px'}} danger onClick={(record_: any) => deleteMembership(record)}>Leave</Button>
        },
    ]
    if(ldap?.ENABLED)
        columns_mem.pop()

    const columns_inv = [
        {
            title: 'Team',
            dataIndex: ['team', 'name'],
            key: 'team',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Date Created',
            dataIndex: 'created',
            key: 'created',
            render: (text: any, record: any) => formatDateAndTime(record.created)
        },
        {
            render: (text: any, record: any) =>
              record.is_accepted?
                <>accepted</>
                :
                <div>
                    <Button style={{ marginRight: '5px'}} onClick={(record_: any) => actionInvitation(record, "accept")}>Accept</Button>
                    <Button style={{ marginRight: '5px'}} danger onClick={(record_: any) => actionInvitation(record, "reject")}>Reject</Button>
                </div>
        },
    ]

    const columns_jrq = [
        {
            title: 'Team',
            dataIndex: ['team', 'name'],
            key: 'team',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Date Created',
            dataIndex: 'created',
            key: 'created',
            render: (text: any, record: any) => formatDateAndTime(record.created)
        },
        {
            render: (text: any, record: any) => <Button danger onClick={(record_: any) => deleteJoinRequest(record)}>Delete</Button>
        },
    ]

    const styleRow = {padding: '2px', height: "25px"}

    return (
      userData &&
      <>
          <Card>
              <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                      <Row style={styleRow}><Text strong>Username</Text></Row>
                      <Row style={styleRow}><Text strong>Email</Text></Row>
                      <Row style={styleRow}><Text strong>Name</Text></Row>
                      <Row style={styleRow}><Text strong>Active Team</Text></Row>
                  </Col>
                  <Col className="gutter-row" span={6}>
                      <Row style={styleRow}>{userData.username}</Row>
                      <Row style={styleRow}>{userData.email.name}</Row>
                      <Row style={styleRow}>{userData.first_name + " " + userData.last_name}</Row>
                      <Row style={styleRow}>{userData?.active_team?.name}</Row>
                  </Col>
              </Row>
          </Card>
          <Card title="Memberships" style={{marginTop: "8px"}}>
              {
                  userMemberships &&
                  <SkeletonTable columns={columns_mem as SkeletonTableColumnsType[]}>
                      <Table
                          rowKey='id'
                          columns={columns_mem}
                          dataSource={userMemberships}
                          pagination={false}
                      />
                  </SkeletonTable>
              }
          </Card>
          {
              ldap?.ENABLED?
                <></>
                :
                <Card style={{marginTop: "8px"}}>
                    <Tabs defaultActiveKey="1" type='card'>
                        <TabPane tab="Invitations" key="1">
                            {
                                userInvitations &&
                                <SkeletonTable columns={columns_inv as SkeletonTableColumnsType[]}>
                                    <Table
                                        rowKey='id'
                                        columns={columns_inv}
                                        dataSource={userInvitations}
                                        pagination={false}
                                    />
                                </SkeletonTable>
                            }
                        </TabPane>
                        <TabPane tab="Join Requests" key="2">
                            {
                                userJoinRequests &&
                                <SkeletonTable columns={columns_jrq as SkeletonTableColumnsType[]}>
                                    <Table
                                        rowKey='id'
                                        columns={columns_jrq}
                                        dataSource={userJoinRequests}
                                        pagination={false}
                                    />
                                </SkeletonTable>
                            }
                            <Button onClick={() => {setIsModalOpen(true)}} type="primary" style={{ margin: '10px' }}>Create join request</Button>
                            <Modal
                              title='Create join request'
                              visible={isModalOpen}
                              footer={[
                                // <Button key="back" onClick={() => setIsModalOpen(false)}>Cancel</Button>,
                                // <Button key="submit" type="primary" onClick={createJoinRequestForm.submit}>Submit</Button>
                              ]}
                            >
                                identity?
                                <Form
                                  form={form}
                                  name="join_team"
                                  className="row-col"
                                  initialValues={{ remember: true }}
                                  onFinish={onJoinRequestSubmit}
                                >
                                    <Form.Item name="team" rules={[{ required: true, message: 'Please input Team Name' }]}>
                                        <Select
                                          showSearch
                                          options={allTeams}
                                          optionFilterProp="children"
                                          filterOption={(input: string, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button style={{ width: "100%" }} type="primary" htmlType="submit" className="form-button">Request to Join</Button>
                                    </Form.Item>
                                </Form>
                                :
                                <></>
                            </Modal>
                        </TabPane>
                    </Tabs>
                </Card>
          }
      </>
    )

}

export default ProfileEdit;
