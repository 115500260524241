import {Button, Card, Collapse, Divider, Form, Typography, Select} from 'antd';
import React, {useEffect} from 'react';
import {useDataProvider, useNotify} from 'react-admin';
import DebounceSelect from '../../Common/DebounceSelect';
import {ExtendedDataProvider} from "../../../types";

const { Panel } = Collapse;
const { Option } = Select;
const { Title, Paragraph, Text } = Typography;


const TeamMappings = ({ team }: { team: any }) => {
    const notify = useNotify()
    const [form] = Form.useForm();
    const dataProvider: ExtendedDataProvider = useDataProvider()

    const fetchTeamMappings = () => {
        dataProvider.getAction("teams", { id: team.id, verb: 'team_mappings' })
            .then((response: any) => {
                form.setFieldsValue({
                    ADMIN: response.data?.ADMIN?.name,
                    GENERAL: response.data?.GENERAL?.name,
                    READ_ONLY: response.data?.READ_ONLY?.name,
                })
            })
            .catch((error: any) => notify('Error fetching', 'error'))
    }

    useEffect(() => {
        fetchTeamMappings()
    }, [])

    const LDAPGroupNotFoundSelectOption = (inputValue: string) => <Option key={inputValue} value={inputValue}>{inputValue}</Option>

    const onSubmit = async (values: any) => {
        for(const role in values)
            if(!values[role])
                values[role] = null

        dataProvider.createAction(
            'teams', {data: values, id: team.id, verb: 'team_mappings/'})
            .then(() => {
                notify("Team mappings are updated")
                fetchTeamMappings()
            })
            .catch (error => {
                if (error.status == 400)
                    if("non_field_errors" in error.body) {
                        notify ("" + error.body['non_field_errors'], 'error')
                        return
                    }
                notify ('Error updating team mappings', 'error')
            })
    }

    const fetchGroupList = async (values: string): Promise<any> => {
        return dataProvider.getList(
            'groups',
            {
                pagination: { page: 1, perPage: 20 },
                sort: { field: 'name', order: 'asc' },
                filter: {"name": values}
            })
            .then((data: any) => data.data.map((result: any) => ({label: result.name, value: result.id})))
    }

    return (
        <Card
            bordered={false}
            bodyStyle={{ padding: '20px' }}
            className="criclebox tablespace mb-24"
            title="Team Mappings"
        >
            <Collapse defaultActiveKey={[]} >
                <Panel header="How it works?" key="1" style={{ marginBottom: '20px' }}>
                    <Typography>
                        <Paragraph>
                            It is possible to connect your LDAP groups to a NetOrca team.
                            Each LDAP group can have one role in one NetOrca team.
                            You can select a suitable LDAP group for each role by searching
                            its' name using the corresponding search field in the form below.
                        </Paragraph>

                        <Title level={5}>The Roles: </Title><br/>
                        <Text style={{'marginRight': "5px"}} strong >ADMIN:</Text>
                        This role has view and modify access to all items.<br/>

                        <Text style={{'marginRight': "5px"}} strong>GENERAL:</Text>
                        This role has view only access to all items. However, in ServiceOwner teams, General users are also able to process change instances.<br/>

                        <Text style={{'marginRight': "5px"}} strong>READ_ONLY:</Text>
                        This role has view only access to all items.

                        <Divider />
                    </Typography>
                </Panel>
            </Collapse>
            <Form form={form} onFinish={onSubmit} labelCol={{span: 3}} labelAlign={'left'} className="row-col">
                <Form.Item label="Admin Role" name="ADMIN">
                    <DebounceSelect
                        id={"2"}
                        createable
                        showSearch
                        allowClear
                        onChangeSelect={(newValue:any) => {form.setFieldsValue({ADMIN: newValue?.label})}}
                        placeholder="Select Group"
                        fetchOptions={fetchGroupList}
                        NotFoundSelectOption={LDAPGroupNotFoundSelectOption}
                        style={{ width: '25%' }}
                    />
                </Form.Item>
                <Form.Item label="General Role" name="GENERAL">
                    <DebounceSelect
                        id={"1"}
                        createable
                        showSearch
                        allowClear
                        onChangeSelect={(newValue:any) => {form.setFieldsValue({GENERAL: newValue?.label})}}
                        placeholder="Select Group"
                        fetchOptions={fetchGroupList}
                        NotFoundSelectOption={LDAPGroupNotFoundSelectOption}
                        style={{ width: '25%' }}
                    />
                </Form.Item>
                <Form.Item label="Read Only Role" name="READ_ONLY">
                    <DebounceSelect
                        id={"3"}
                        createable
                        showSearch
                        allowClear
                        onChangeSelect={(newValue:any) => {form.setFieldsValue({READ_ONLY: newValue?.label})}}
                        placeholder="Select Group"
                        fetchOptions={fetchGroupList}
                        NotFoundSelectOption={LDAPGroupNotFoundSelectOption}
                        style={{ width: '25%' }}
                    />
                </Form.Item>
                <Form.Item>
                    <Button style={{ width: "10%" }} type="primary" htmlType="submit" className="form-button">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default TeamMappings;