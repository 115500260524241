import {
    CopyOutlined, EditOutlined, ExclamationCircleOutlined, EyeInvisibleOutlined, EyeTwoTone
} from '@ant-design/icons';
import {Button, Card, Form, Input, Modal, Switch, Table} from 'antd';
import React, { useState } from 'react';
import {Record, useDataProvider, useNotify, useQuery, useRedirect, useRefresh} from 'react-admin';
import { formatDateAndTime } from '../../helpers/utils';
import SkeletonTable, { SkeletonTableColumnsType } from '../Common/SkeletonTable';
const { confirm } = Modal;

const DeleteButton = ({ record, style = {}, refetch }: { record: Record, style: any, refetch: any },) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const handleClick = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to delete the api key?',
            onOk() {
                return dataProvider.delete('apikeys', { id: record.id })
                  .then(response => {
                      notify('Api Key deleted')
                      refetch();
                  }).catch(error => {
                      notify("Something went wrong", "warning")
                  })
            },
        });
    };

    return <Button style={style} danger onClick={handleClick}>Delete</Button>;
};

const ApiKeyList = () => {
    const [isApiKeyModalVisible, setIsApiKeyModalVisible] = useState(false);
    const [isApiKeyBeingCreated, setIsApiKeyBeingCreated] = useState(false);

    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(20);
    const [apiKeyForm] = Form.useForm();
    const notify = useNotify();
    const dataProvider = useDataProvider()
    const { loading, error, data, total, refetch } = useQuery({
        type: 'getList',
        resource: 'apikeys',
        payload: {
            pagination: { page: page, perPage: perPage },
            sort: { field: 'created', order: 'asc' },
            filter: {}
        }
    });

    const onSubmitApiKeyForm = (values: any) => {
        setIsApiKeyBeingCreated(true)
        //@ts-ignore
        dataProvider.create('apikeys', { data: values, idField: 'id' })
            .then((response: any) => {
                notify('Key Created Successfully')
                setIsApiKeyBeingCreated(false)
                setIsApiKeyModalVisible(false)
                refetch()
                apiKeyForm.resetFields()
                viewAPIKeyModal(response.data?.key)

            }).catch((error: any) => {
                if (error.status == 400) {
                    for (const [key, value] of Object.entries<any>(error.body)) {
                        apiKeyForm.setFields([
                            { name: key, errors: value }
                        ])
                    }
                    error?.body?.non_field_errors && notify('Error creating API Key', "error")
                    setIsApiKeyBeingCreated(false)
                }
            })
    }

    const onCopy = (key: string) => {
        navigator.clipboard.writeText(key)
        notify('Copied to clipboard')
    }

    const viewAPIKeyModal = (apiKey: string) => {

        Modal.info({
            closable: true,
            width: '50%',
            maskClosable: true,
            title: "API Key",
            content: (
                <div>
                    Please save the following API key somewhere safe as it will not be shown again.
                    <Input.Group compact>
                        <Input.Password
                            readOnly
                            value={apiKey}
                            style={{ width: 'calc(100% - 200px)' }}
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                        <Button icon={<CopyOutlined />} onClick={() => onCopy(apiKey)} />

                    </Input.Group>

                </div>
            )
        });
    }

    const columnsData = [
        {
            title: 'API Key Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Created',
            key: 'key',
            render: (text: any, record: any) => formatDateAndTime(record.created)
        },
        {
            render: (text: any, record: any) =>
              <DeleteButton style={{ float: "right" }} record={record} refetch={refetch}/>
        },
    ];

    const showApiKeyModal = () => {
        setIsApiKeyModalVisible(true);
    };


    return (
        <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="API Keys"
        >
            <SkeletonTable loading={loading} columns={columnsData as SkeletonTableColumnsType[]}>
                <Table
                    rowKey="id" columns={columnsData} dataSource={data}
                    pagination={{
                        pageSizeOptions: [25, 50, 100],
                        onShowSizeChange: (_current, size) => setPerPage(size), showSizeChanger: true,
                        total: total, pageSize: perPage, current: page, onChange: page => setPage(page)
                    }}
                />
            </SkeletonTable>
            <Button onClick={showApiKeyModal} type="primary" style={{ margin: '10px' }}>Create API Key</Button>
            <Modal title="Create API Key"
                onCancel={() => setIsApiKeyModalVisible(false)}
                visible={isApiKeyModalVisible}
                confirmLoading={isApiKeyBeingCreated}
                okText='Create'
                onOk={apiKeyForm.submit}
            >
                <Form
                    form={apiKeyForm}
                    name="api_key"
                    className="row-col"
                    onFinish={onSubmitApiKeyForm}
                >
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: 'Please input your API Key Name' }]}
                    >
                        <Input placeholder="API Key Name" />
                    </Form.Item>
                </Form>
            </Modal>
        </Card>
    )



}

export default ApiKeyList;