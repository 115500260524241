import useGetGeneralSettings from "../../../providers/useGetGeneralSettings";
import {getActiveTeamLocalStorage} from "../../Common/utils";


export const isSubmitButtonVisible = () => {
    const generalSettings = useGetGeneralSettings();
    const activeTeam = getActiveTeamLocalStorage ();

    const submissionBuilderSettings = generalSettings.find(setting => setting.name == 'SubmissionBuilder');
    const visibleFromGeneralSettings = submissionBuilderSettings?.config?.enable_submit_functionality
    const visibleFromTeamPreferences = activeTeam?.preferences?.allow_submit_submission_builder

    return visibleFromGeneralSettings && visibleFromTeamPreferences
}
