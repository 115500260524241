import {Table, Card, Tabs, Spin, Select, Input, Space, Button, Tag} from 'antd';
import React, { useCallback, useState, useEffect } from 'react';
import {
  ListBase, ListProps, useListContext, Record, useDataProvider, useQueryWithStore
} from 'react-admin';
import ReactJson from 'react-json-view';
import SkeletonTable, { SkeletonTableColumnsType } from '../Common/SkeletonTable';
import { ExtendedDataProvider, SelectItem } from '../../types'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import '../../assets/css/github_markdown.css';
import ServiceExample from './ServiceExample';
import {onExpandIcon, tableOnChangeGenerator} from "../Common/tableUtils";
import {useLocation} from "react-router-dom";
import ServiceTagFilter from "../Filters/ServiceTagFilter";
import {renderNameAndTagWithEllipsis} from "../Common/Tags";

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const ListView = () => {
  const { ids, data, loading, loaded, total, perPage, page, setPage, setPerPage, setSort, filterValues, setFilters } = useListContext();

  const dataProvider: ExtendedDataProvider = useDataProvider();
  const [readmeData, setReadmeData] = useState<any>({});

  const [owners, setOwners] = useState<SelectItem[] | null>(null);
  const tableData: Record[] = []

  const [expandedRow, setExpandedRow] = useState(null);
  const location = useLocation();

  const toggleRowExpansion = (key: any) => {
    setExpandedRow(current => (current === key ? null : key));
  }

  useEffect(() => {
    const serviceName = location.hash.substring(1);
    const serviceId = Object.values(data).find(item => item.name === serviceName)?.id || null;
    if(serviceId) {
      toggleRowExpansion(serviceName)
      // @ts-ignore
      fetchReadme(serviceId)
    }
  }, [location, data]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text: any, record: any) => renderNameAndTagWithEllipsis(record.name, record.tags, 10)
    },
    {
      title: 'Description',
      key: 'description',
      render: (text: any, record: any) => record?.schema.schema.description
    },
    {
      title: 'Service Owner',
      dataIndex: ['owner', 'name'],
      key: 'owner',
    },
  ]

  const { data: ownerTeamData } = useQueryWithStore({
    type: 'getList',
    resource: 'serviceowner_teams',
    payload: {
      pagination: { page: 1, perPage: 20 },
      sort: { field: 'name', order: 'asc' },
      filter: {}
    }
  });

  const handleSelectTeam = (value: string) => {
    setFilters({ ...filterValues, 'owner_id': value }, {})
  }

  useEffect(() => {
    const ownerFilter: any[] = []
    if (ownerTeamData) {
      for (let item of ownerTeamData) {
        ownerFilter.push({ 'text': item.name, 'value': item.id })
      }
      setOwners(ownerFilter);
    }
  }, [ownerTeamData])

  const expandedRowRender = (record: any) => {
    return (
      <Card key={record.id}>
        <Space direction={"vertical"}>
          {
            record.tags.length > 0 &&
              <Space style={{margin: '10px'}}>Tags: {record.tags?.map((tag: any) => <Tag>#{tag}</Tag>)}</Space>
          }
        <Tabs defaultActiveKey={record.has_md ? record.id + "_readme" : record.id + "_schema"} type='card'>
          {record.has_md && (<TabPane style={{ padding: '20px' }} tab="Readme" key={record.id + "_readme"} >
            {!readmeData[record.id] && <Spin style={{ 'width': '100%' }} />}
            {readmeData[record.id] && <ReactMarkdown className='markdown-body' rehypePlugins={[rehypeRaw, remarkGfm]} children={String(readmeData[record.id])} />}
          </TabPane>)}
          <TabPane tab="Schema" key={record.id + "_schema"}>
            <ReactJson  collapseStringsAfterLength={50} style={{ padding: '5px 15px'}} src={record.schema.schema} displayArrayKey={false} displayDataTypes={false} name={false} displayObjectSize={false} />
          </TabPane>
          <TabPane tab="Example" key={record.id + "_example"}>
            <ServiceExample style={{ padding: '5px 15px' }} record={record} />
          </TabPane>
        </Tabs>
        </Space>
      </Card>
    )
  }

  const onExpandReadme = useCallback((expanded, record) => {
    if(expanded)
      fetchReadme(record.id)
  }, [dataProvider, loaded, tableData, readmeData]);

  const fetchReadme = (service_id: number) => {
    dataProvider.getActionNonJson('browse_services', { id: service_id, verb: 'docs' })
        .then((response: any) => {
          setReadmeData((prev: any) => ({...prev,  [service_id]: response.data.replace(/\\n/gi, '  \n')}))
        })
        .catch((error: any) => { console.log(error) })
  }

  const onSearch = (value: string) => {
    setFilters({ ...filterValues, name: value }, {});
  }

  ids.forEach(id => {
    tableData.push(data[id])
  });
  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      title="Service Catalogue"
      extra={
        <Space size={'small'} direction='vertical'>
          <Search
            className='item-search'
            allowClear
            placeholder="Search by name"
            onSearch={onSearch}
            enterButton
          />
          <Select data-cy="team-selector" defaultValue={filterValues.owner_id} placeholder="Select Team" style={{ display: 'block'}} onChange={handleSelectTeam}>
            <Option key={0} value="">All Teams</Option>
            {owners && owners.map(team => {return <Option key={team.value} value={team.value}>{team.text}</Option>})}
          </Select>
          <ServiceTagFilter filterValues={filterValues} setFilterValues={setFilters} />
        </Space>
    }>

      <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
        <Table
          rowKey='name'
          columns={columns}
          dataSource={tableData}
          pagination={{
            pageSizeOptions: [25, 50, 100],
            onShowSizeChange: (_current, size) => setPerPage(size),
            showSizeChanger: true,
            total: total,
            pageSize: perPage,
            current: page,
          }}
          onChange={tableOnChangeGenerator(setSort, page, setPage)}
          expandable={{
            expandedRowRender,
            expandRowByClick: true,
            onExpand: onExpandReadme,
            expandedRowKeys: expandedRow ? [expandedRow] : [],
            expandIcon: onExpandIcon(toggleRowExpansion, onExpandReadme),
          }}
          onRow={(record) => { return { onClick: () => { toggleRowExpansion(record.name) } } }}
        />
      </SkeletonTable>
    </Card>
  )
}

const ServiceCatalogueList = (props: ListProps) => (
  <ListBase perPage={25} {...props}>
    <ListView />
  </ListBase>
)

export default ServiceCatalogueList;
