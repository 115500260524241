import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title as ChartTitle,
    Tooltip,
    Legend,
    TimeUnit
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ChartTitle,
    Tooltip,
    Legend,
    TimeScale
);

const AverageTimeCompletionGraphWidget = ({ data, unit = 'month' }: { data: any, unit: TimeUnit }) => {
    if (Object.keys(data).length === 0) {
        return null
    }
    return (
        <Line
            data={data}
            options={{
                scales: {
                    x: {
                        display: true,
                        type: 'time',
                        time: {
                            ...unit == 'month' && { tooltipFormat: 'MMMM yyyy' },
                            ...unit == 'day' && { tooltipFormat: 'dd MMMM yyyy' },
                            ...unit == 'week' && { tooltipFormat: 'ww' },
                            unit: unit,
                            parser: 'dd/MM/yyyy',
                            displayFormats: {
                                'month': 'MMMM yyyy'

                            }
                        }
                    },
                    y: {
                        display: true,
                        title: {
                            display: true,
                            text: data.yLabel,
                        },
                        beginAtZero: true,
                    }

                },
                plugins: {
                    title: {
                        display: true,
                        color: 'rgba(0, 0, 0, 0.85)',
                        text: 'Average Time to Complete Change Instances',
                        font: {
                            size: 16,
                            weight: '600',
                        }
                    },
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            title: (tooltip) =>
                                unit == 'week' ? `Week ${tooltip[0].label}` : tooltip[0].label,
                            label: (item) => `${item.formattedValue} ${data.yLabel}`,
                        },
                    },
                }

            }}
        />
    )
}

export default AverageTimeCompletionGraphWidget