
import moment from 'moment';

export const formatDateAndTime = (dateTime: string) => {
    if(!dateTime) {
        return "";
    }
    return new Date(dateTime).toLocaleDateString('en-GB') + " " + new Date(dateTime).toLocaleTimeString()
}

export const formatDateAndTimeFromNow = (dateTime: string) => {
    return moment(dateTime).fromNow()
}
