import {Button, Card, Space, Spin, Tooltip, Typography} from 'antd';
import React from 'react';
import { useQuery, useNotify } from 'react-admin';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { default as PrismYaml } from 'react-syntax-highlighter/dist/esm/languages/prism/yaml';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import yaml from 'js-yaml';
import { CopyOutlined } from '@ant-design/icons';

SyntaxHighlighter.registerLanguage('yaml', PrismYaml);


const ServiceExample = ({ style, record }: { style?: React.CSSProperties, record: any }) => {
    const notify = useNotify();
    const spinnerElement = <Spin size="large" tip="Loading"><Card style={{height: "100px"}}></Card></Spin>

    const {data} = useQuery ({
        type: 'getActionNonJson',
        resource: 'browse_services',
        payload: {id: record.id, verb: 'examples/', filter: {yaml: true}},
    });


    const onCopy = (data: string) => {
        navigator.clipboard.writeText(data)
        notify('Copied to clipboard')
    }

    return (
        <div style={style}>
            {
                data ?
                    <>
                        <Space direction={"horizontal"}>
                            <Typography.Title level={5}>
                                YAML
                            </Typography.Title>
                            <Tooltip title="Copy To Clipboard">
                                <Button
                                    icon={<CopyOutlined/>}
                                    onClick={() => onCopy (yaml.dump (yaml.load (data)))}
                                    style={{float: 'right', padding: 0}}
                                />
                            </Tooltip>
                        </Space>
                        <SyntaxHighlighter
                            customStyle={{backgroundColor: 'white'}}
                            style={materialLight}
                            language="yaml">
                            {yaml.dump (yaml.load (data))}
                        </SyntaxHighlighter>
                    </>
                    :
                    spinnerElement
            }
        </div>
    )
}

export default ServiceExample;