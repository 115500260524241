import ReactDiffViewer from "react-diff-viewer";
import React from "react";

const DeclarationDiff = (record: any) => {
    const { change_instance } = record
    return (
      <ReactDiffViewer
        leftTitle={change_instance?.old_declaration ? `Version ${change_instance?.old_declaration?.version}` : "Previous Declaration"}
        rightTitle={change_instance?.new_declaration ? `Version ${change_instance?.new_declaration?.version}` : 'Removed'}
        oldValue={JSON.stringify(change_instance?.old_declaration?.declaration, null, 4)}
        newValue={JSON.stringify(change_instance?.new_declaration?.declaration, null, 4)}
        splitView={true}
      />
    )
}

export default DeclarationDiff;
