import React from "react";
import {Card, Tabs} from "antd";
import MetadataDiffView from "./SubComponents/MetadataDiffView";
import TabPane = Tabs.TabPane;
import ChangeInstanceExpandedTable from "./SubComponents/ChangeInstanceExpandedTable";

const SubmissionExpandedRow = ({ record }: { record: Record<string, any> }) => {
    return (
        <Card>
            <Tabs defaultActiveKey="1" type='card'>
                <TabPane tab="Change Instances" key="1">
                    <ChangeInstanceExpandedTable resource={"change_instances"} filterParam={{ submission_id: record.id, light: true }}/>
                </TabPane>
                <TabPane tab="Metadata" key="2">
                    <MetadataDiffView submission={record}/>
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default SubmissionExpandedRow;
