import {Card, Table, Tabs, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import { Link, ListBase, ListProps, useListContext } from 'react-admin';
import { formatDateAndTime } from '../../helpers/utils';
import SkeletonTable, { SkeletonTableColumnsType } from '../Common/SkeletonTable';
import DependantServiceItems from './DependantServiceItems';
import {getTagForRuntimeStates} from "../Common/Tags";
import GeneralFilter from "../ChangeInstances/GeneralFilter";
import {tableOnChangeGenerator} from "../Common/tableUtils";

const { TabPane } = Tabs;

const ListView = () => {
  const { ids, data, loading, setSort, filterValues, setFilters, resource, perPage, setPage, setPerPage, page, total } = useListContext();
  const [columns, setColumns] = useState<any[]>([])

  useEffect(() => {
    const columns_ = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: {key: "id"},
      },
      {
        title: 'Service Item',
        dataIndex: 'name',
        key: 'name',
        render: (text: any, record: any) =>
        (
            <div title={record.name} style={{ display: 'grid', placeItems: 'stretch' }}>
                <Link style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} to={`/owner_service_items/${record.id}`}>{record.name}</Link>
            </div>
        ),
        sorter: {key: "name"},
      },
      {
        title: 'State',
        dataIndex: 'runtime_state',
        key: 'runtime_state',
        render: (text: any, record: any) => getTagForRuntimeStates(record.runtime_state),
        sorter: {key: "runtime_state"},
      },
      {
        title: 'Application',
        dataIndex: ['application', 'name'],
        key: 'application',
      },
      {
        title: 'Service',
        dataIndex: ['service', 'name'],
        key: 'service',
      },
      {
        title: 'Consumer Owner',
        dataIndex: ['consumer_team', 'name'],
        key: 'consumer_team',
      },
      {
        title: 'Last Modified',
        key: 'modified',
        render: (text: any, record: any) => formatDateAndTime(record.modified),
        sorter: {key: "modified"},
      },
    ];
    setColumns(columns_);
  }, [])


  const tableData: Array<Object> = [];
  ids.forEach(id => {
    tableData.push(data[id])
  });
  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      title="Service Items"
    >
      <Tabs style={{ paddingLeft: '24px' }} defaultActiveKey='1'>
        <TabPane tab="Service Items" key="1">
          <Space style={{ width: '100%' }} direction="vertical">
            <GeneralFilter
                filterValues={filterValues}
                setFilterValues={setFilters}
                applicationsResource={"owner_applications"}
                teamsResource={"consumer_teams"}
                servicesResource={"services"}
                style={{ float: 'right', margin: '20px', width: "350px" }}
                stateFilterEnabled={false}
                changeTypeFilterEnabled={false}
            />
          </Space>

          <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={tableData}
              pagination={{
                onShowSizeChange: (_current, size) => setPerPage(size), showSizeChanger: true,
                total: total, pageSize: perPage,
                pageSizeOptions: [25, 50, 100], 
                current: page, onChange: page => setPage(page)
              }}
              onChange={tableOnChangeGenerator(setSort, page, setPage)}
            />
          </SkeletonTable>
        </TabPane>
        <TabPane tab="Dependant Service Items" key="2">
          <DependantServiceItems />
        </TabPane>
      </Tabs>
    </Card>
  )
}

const ServiceItemsList = (props: ListProps) => (
  <ListBase
    sort={{ field: 'id', order: 'DESC' }}
    filter={{light: true}}
    perPage={25}
    {...props}
  >
    <ListView />
  </ListBase>
)

export default ServiceItemsList;
